import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import './modal.css';

const modalRoot = document.getElementById('portalModal');

function Modal({ isOpen = false, children, style }) {
  const [shouldRender, setShouldRender] = useState(isOpen);
  const [isClosing, setIsClosing] = useState(false);
  const [modalAnimation, setModalAnimation] = useState('');
  const [overlayAnimation, setOverlayAnimation] = useState('');
  const closeTimer = useRef(null);

  useEffect(() => {
    if (isOpen) {
      if (closeTimer.current) clearTimeout(closeTimer.current);
      setShouldRender(true);
      setIsClosing(false);
      setModalAnimation('modal-enter');
      setOverlayAnimation('overlay-enter');
    }
  }, [isOpen]);

  const handleClose = (callback) => {
    if (!isClosing) {
      setIsClosing(true);
      setModalAnimation('modal-exit');
      setOverlayAnimation('overlay-exit');

      closeTimer.current = setTimeout(() => {
        setShouldRender(false);
        if (typeof callback === 'function') {
          callback();
        }
      }, 400);
    }
  };

  useEffect(() => {
    return () => {
      if (closeTimer.current) clearTimeout(closeTimer.current);
    };
  }, []);

  if (!shouldRender) return null;

  return createPortal(
    <div className="modal-container">
      <div className={`overlay ${overlayAnimation}`} />
      <div style={{ ...style }} className={`modall ${modalAnimation}`}>
        {React.cloneElement(children, { closeFn: handleClose })}
      </div>
    </div>,
    modalRoot
  );
}

export default Modal;
