/**
 *
 * SplanZpressBox ant TEST
 *
 */

import { useState } from 'react';
import { LineBoxWrapper, DataInfo, SubInfo } from '../LineBoxWrapper';
import { useHistory } from 'react-router-dom';
import Button from '../../../Button';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import FormattedMessage from '../../../TranslationWrapper';
import { InfoTextField } from '../../../reusable/InfoTextField';

import { useDispatch } from 'react-redux';
import { MoreIcon, SendSignalIcon, SettingsIcon } from '../../../reusable/Icons';
import Modal from '../../../common/Modal/Modal';
import ControlModal from '../../../../containers/EditControlModal/index';
import globalMessages from '../../../../globalMessages';
import { sendToWs } from '../../../../containers/Websocket/actions';

export function SplanZpressBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  const [isModal1Opened, setIsModal1Opened] = useState(false);

  function toggleModal(string) {
    setIsModal1Opened(false);
  }

  let orderColor = null;
  const lamellaAttribute = _.get(props, 'configData.lamellaNumAttrib', '');
  if (_.get(props, 'productionstatus.producing', null) != null) {
    orderColor = props.productionstatus.producing.orderColor;
  }

  // console.log('orderColor: ', orderColor);
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <LineBoxWrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo>{props.subtitle}</SubInfo>
      </Paper>
      <br />
      <div style={{ height: '100%' }}>
        <DataInfo>
          <div style={{ width: '95%', textAlign: 'right', position: 'absolute', marginRight: '10px' }}>{/* <img style={{ width:'30px'}}  src={Planer} alt="Saw" /> */}</div>
          <div style={{ display: 'grid', marginLeft: '5px', marginRight: '5px' }}>
            <br />
            <InfoTextField
              label={<FormattedMessage {...globalMessages.dimensions} />}
              // color={orderColor}
              id={'lineBoxInfo'}
              value={
                props.productionstatus?.[props.configData?.orderObject] != null
                  ? props.productionstatus[props.configData?.orderObject].lamellaWidth +
                    ' x ' +
                    props.productionstatus[props.configData?.orderObject].lamellaThickness +
                    ' x ' +
                    props.productionstatus[props.configData?.orderObject].lamellaLength +
                    ' mm'
                  : ''
              }
              variant="outlined"
              size="small"
              focused
              InputProps={{ readOnly: true }}
            />
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => setIsModal1Opened(true)}>
              <SettingsIcon className="prodlineMore" style={{ width: '2rem', height: '2rem' }} />
              {/* Set drying */}
            </Button>
            <Modal id={'set-zink'} target={'set-zink'} isOpen={isModal1Opened} style={{ width: '50%', height: '80%', display: 'flex', flexDirection: 'column', zIndex: '2300' }}>
              <ControlModal
                action={'set-zink'}
                screenstyle={'small'}
                closeCallback={() => {
                  toggleModal();
                }}
              />
            </Modal>
          </div>
          <InfoTextField
            label={<FormattedMessage {...globalMessages.order} identifier={'order'} />}
            color={orderColor}
            id={'lineBoxInfo'}
            value={
              props.productionstatus?.[props.configData?.orderObject] != null
                ? props.productionstatus[props.configData?.orderObject].id +
                  ' | ' +
                  props.productionstatus[props.configData?.orderObject].type +
                  ' | ' +
                  props.productionstatus[props.configData?.orderObject].quality
                : ''
            }
            variant="standard"
            size="small"
            focused
            InputProps={{ readOnly: true }}
          />
        </DataInfo>
        <DataInfo>
          {_.get(props, 'productionstatus.' + props.configData?.orderObject + '.' + lamellaAttribute, null) != null && (
            <InfoTextField
              label={<FormattedMessage {...globalMessages.lamellas} identifier={'lamellas'} />}
              id={'lineBoxInfo'}
              className={'animateNumber'}
              value={
                props.productionstatus[props.configData?.orderObject] != null
                  ? props.productionstatus[props.configData?.orderObject][lamellaAttribute] + ' / ' + props.productionstatus[props.configData?.orderObject].numLamellas
                  : ''
              }
              variant="standard"
              size="small"
              color="primary"
              focused
              InputProps={{ readOnly: true }}
            />
          )}
        </DataInfo>
        <div style={{ position: 'absolute', width: '100%', textAlign: 'center' }}>
          <Button source="prodlineButton" onClick={() => dispatch(sendToWs({}, 'resend-zinc-data'))}>
            <SendSignalIcon /> <FormattedMessage {...globalMessages.resendData} />
          </Button>
        </div>
        <div style={{ position: 'absolute', width: '100%', bottom: '60px', textAlign: 'center' }}>
          <Button
            onClick={() =>
              history.push({
                pathname: props?.configData?.route,
                props: props.status
              })
            }>
            <MoreIcon className="prodlineMore" style={{ width: '2rem', height: '2rem' }} />
            {/* Detailed view */}
          </Button>
        </div>
      </div>
    </LineBoxWrapper>
  );
}

SplanZpressBox.propTypes = {};

export default SplanZpressBox;
