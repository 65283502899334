/**
 *
 * AccumulatorBox
 *
 */

import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import styled from 'styled-components';
import './test.css';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import Button from '../../../Button';
import StatusItem from '../../../StatusItem';
import { MoreIcon, SettingsIcon } from '../../../reusable/Icons';
import { sendToWs } from '../../../../containers/Websocket/actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import Modal from '../../../common/Modal/Modal';
import ControlModal from '../../../../containers/EditControlModal/index';

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

const DataInfo = styled.div`
  background: #ffffffd4;
  padding: 10px;
  //box-shadow: 0px 0px 3px 1px #636f8373;
  display: inline-block;
  width: 100%;
`;

export function PressBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  // const history = useHistory();

  // const title = props.message;

  const [isModal1Opened, setIsModal1Opened] = useState(false);

  function toggleModal(string) {
    if (string === 'set-no-group') {
      setIsModal1Opened(!isModal1Opened);
      return;
    }

    setIsModal1Opened(false);
  }

  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo> B11 | Press Box </SubInfo>
      </Paper>
      <br />
      <DataInfo>
        <StatusItem item={_.get(props, 'status.noGroupMode', null)} type="green">
          No group mode (NGM)
        </StatusItem>
        <div>{_.get(props, 'status.noGroupModeWidthTakeaway', null)} mm (NGM width takeaway)</div>
        <div>{_.get(props, 'status.noGroupModeCOversize', null)} mm (NGM C oversize)</div>
        <div>{_.get(props, 'status.noGroupModeLOversize', null)} mm (NGM L oversize)</div>
        <div>{_.get(props, 'status.kvhOversize', null)} mm (KVH oversize)</div>
        <div>{_.get(props, 'status.xcutOversize', null)} mm (CROSSCUT oversize)</div>
        <span style={{ float: 'right' }}>
          <Button onClick={() => setIsModal1Opened(true)}>
            <SettingsIcon style={{ width: '1.2rem', height: '1.2rem' }} />
            {/* Set drying */}
          </Button>
          <Modal id={'set-no-group'} target={'set-no-group'} isOpen={isModal1Opened} style={{ width: '50%', height: '80%', display: 'flex', flexDirection: 'column', zIndex: '2300' }}>
            <ControlModal
              action={'set-no-group'}
              screenstyle={'small'}
              closeCallback={() => {
                toggleModal();
              }}
            />
          </Modal>
        </span>
      </DataInfo>
      <div style={{ position: 'absolute', width: '100%', bottom: '0px' }}>
        <Button
          onClick={() => {
            props.sendToWs({ lagerVollProzent: 100 }, 'no-group-recalc');
          }}>
          <MoreIcon style={{ width: '2rem', height: '2rem' }} />
        </Button>
      </div>
    </Wrapper>
  );
}

PressBox.propTypes = {
  status: PropTypes.object,
  sendToWs: PropTypes.func
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    sendToWs: (identifier, data) => dispatch(sendToWs(identifier, data)),
    dispatch
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(PressBox);
