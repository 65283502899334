/**
 *
 * AccumulatorBox
 *
 */

import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import styled from 'styled-components';
import './test.css';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import Button from '../../../Button';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import StatusItem from '../../../StatusItem';
import { MoreIcon, SettingsIcon } from '../../../reusable/Icons';
import Modal from '../../../common/Modal/Modal';
import ControlModal from '../../../../containers/EditControlModal/index';
import { addModal } from '../../../../containers/ModalManager/actions';

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

const DataInfo = styled.div`
  background: #ffffffd4;
  padding: 10px;
  //box-shadow: 0px 0px 3px 1px #636f8373;
  display: inline-block;
  width: 100%;
`;

const EtageBox = styled.div`
  padding: 5px;
  font-size: 8px;
  font-weight: lighter;
  display: inline-block;
  opacity: 0.8;
  margin-left: 5px;
  margin-right: 5px;
  width: 13px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

const EmptyEtageBox = styled.div`
  padding: 5px;
  font-size: 8px;
  font-weight: lighter;
  display: inline-block;
  opacity: 0.8;
  margin-left: 5px;
  margin-right: 5px;
  width: 13px;
`;

export function EtageLagerBox(props) {
  // eslint-disable-line react/prefer-stateless-function
  const [isModal1Opened, setIsModal1Opened] = useState(false);

  function toggleModal(string) {
    if (string === 'set-drying') {
      setIsModal1Opened(!isModal1Opened);
      return;
    }

    setIsModal1Opened(false);
  }

  function mapReverse(reverse) {
    const reversed = reverse.map((num, index, reverse) => reverse[reverse.length - 1 - index]);
    return reversed;
  }

  return (
    <Wrapper>
      <Paper elevation={1} style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}>
        <SubInfo> B11 | Etages Lager Drying </SubInfo>
      </Paper>
      <br />
      <DataInfo>
        <StatusItem item={_.get(props, 'status.manualFilling', null)} type="green">
          Manual filling
        </StatusItem>
        <StatusItem item={_.get(props, 'status.manualEmptying', null)} type="green">
          Manual emptying
        </StatusItem>
        <span style={{ float: 'right' }}>
          <Button onClick={() => setIsModal1Opened(true)}>
            <SettingsIcon style={{ width: '1.2rem', height: '1.2rem' }} />
            {/* Set drying */}
          </Button>
          <Modal id={'set-drying'} target={'set-drying'} isOpen={isModal1Opened} style={{ width: '50%', height: '70%', display: 'flex', flexDirection: 'column', zIndex: '2300' }}>
            <ControlModal
              action={'set-drying'}
              screenstyle={'small'}
              closeCallback={() => {
                toggleModal();
              }}
            />
          </Modal>
        </span>
      </DataInfo>
      <div style={{ margin: '0 auto', display: 'table', padding: '20px' }}>
        {props.status !== undefined &&
          props.status !== null &&
          mapReverse(props.status.etages).map((etage, i) => (
            <div key={i} style={{ display: 'flex' }}>
              {etage.compartments !== undefined &&
                etage.compartments !== null &&
                mapReverse(etage.compartments).map((compartment, j) => {
                  let orderColor = 'white';
                  if (compartment.orderColor !== undefined && compartment.orderColor !== null && compartment.orderColor.length > 0) {
                    orderColor = compartment.orderColor;
                  }
                  {
                    return (
                      <div key={`${i}${j}`}>
                        <EtageBox
                          key={`${i}${j}`}
                          style={{
                            backgroundColor: orderColor,
                            color: 'white'
                          }}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          ))}
        {props.status !== undefined && props.status !== null && (
          <div key={'lower'} style={{ display: 'flex', alignItems: 'center' }}>
            {mapReverse([props.status.chains1, props.status.chains2, props.status.chains3, props.status.chains4, props.status.abz1, null, null]).map((compartment, j) => {
              let orderColor = 'white';
              if (compartment != null && compartment.orderColor !== undefined && compartment.orderColor !== null && compartment.orderColor.length > 0) {
                orderColor = compartment.orderColor;
              }
              return (
                <div key={`${'lower'}${j}`}>
                  {compartment != null ? (
                    <EtageBox
                      key={`${'lower'}${j}`}
                      style={{
                        backgroundColor: orderColor,
                        color: 'white'
                      }}
                    />
                  ) : (
                    <EmptyEtageBox />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div style={{ position: 'absolute', width: '100%', bottom: '0' }}>
        <Button>
          <MoreIcon style={{ width: '2rem', height: '2rem' }} onClick={() => props.dispatch(addModal('modal-drying'))} />
        </Button>
      </div>
    </Wrapper>
  );
}

EtageLagerBox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  status: PropTypes.object
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(EtageLagerBox);
