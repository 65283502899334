/*
 *
 * Websocket reducer
 *
 */

import produce from 'immer';
import { DEFAULT_ACTION, CLT_STATUS_RECEIVED, SCANNER_STATUS_RECEIVED } from './constants';
import ReconnectingWebSocket from './reconnecting-websocket';

export const initialState = {
  cltStatusReceivedTimestamp: 0,
  scannerStatusReceivedTimestamp: 0,
  websocketObject: new ReconnectingWebSocket(`ws://${window.location.host}/websocket/web/`),
  cltstatus: '',
  hash: ''
};

const websocketReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CLT_STATUS_RECEIVED:
        draft.cltStatusReceivedTimestamp = new Date();
        draft.cltstatus = action.cltstatus;
        break;
      case SCANNER_STATUS_RECEIVED:
        draft.scannerStatusReceivedTimestamp = new Date();
        break;
    }
  });

export default websocketReducer;
