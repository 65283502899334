/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import English from '../../translations/en.json';
import German from '../../translations/de.json';
import Portuguese from '../../translations/pt.json';
import Swedish from '../../translations/se.json';
import Finnish from '../../translations/fi.json';
import Spanish from '../../translations/es.json';
import Hebrew from '../../translations/he.json';
import Slovenian from '../../translations/sl.json';
import French from '../../translations/fr.json';
import Estonian from '../../translations/et.json';

// import { DEFAULT_LOCALE } from '../App/constants';
import projectList from '../App/config/projectList';

export const Context = React.createContext();
const project_name = process.env.REACT_APP_PROJECT_NAME;
const DEFAULT_LOCALE = projectList[project_name].language.defaultLang;

const initialLocale = localStorage.getItem('language') || DEFAULT_LOCALE;

const getLanguageMessages = (locale) => {
  switch (locale) {
    case 'en':
      return English;
    case 'de':
      return German;
    case 'pt':
      return Portuguese;
    case 'se':
      return Swedish;
    case 'fi':
      return Finnish;
    case 'es':
      return Spanish;
    case 'he':
      return Hebrew;
    case 'sl':
      return Slovenian;
    case 'fr':
      return French;
    case 'et':
      return Estonian;
    default:
      return English; // Fallback to English or DEFAULT_LOCALE messages
  }
};

const LanguageProvider = (props) => {
  // console.log("LanguageProvider props ", props);
  const [locale, setLocale] = useState(initialLocale);
  const [messages, setMessages] = useState(getLanguageMessages(initialLocale));

  useEffect(() => {
    localStorage.setItem('language', locale);
  }, [locale]);

  function selectLanguage(newLocale) {
    setLocale(newLocale);
    setMessages(getLanguageMessages(newLocale));
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider
        locale={locale}
        key={props.locale}
        // messages={props.messages[props.locale]}
        messages={messages}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default LanguageProvider;
/* LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = createSelector(
  makeSelectLocale(),
  locale => ({
    locale,
  }),
);

export default connect(mapStateToProps)(LanguageProvider); */
