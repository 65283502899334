/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
import '@formatjs/intl-relativetimeformat/polyfill.js';
import '@formatjs/intl-relativetimeformat/locale-data/en.js';
import '@formatjs/intl-relativetimeformat/locale-data/de.js';
import '@formatjs/intl-relativetimeformat/locale-data/pt.js';
import '@formatjs/intl-relativetimeformat/locale-data/se.js';
import '@formatjs/intl-relativetimeformat/locale-data/fi.js';
import '@formatjs/intl-relativetimeformat/locale-data/he.js';
import '@formatjs/intl-relativetimeformat/locale-data/es.js';
import '@formatjs/intl-relativetimeformat/locale-data/sl.js';
import '@formatjs/intl-relativetimeformat/locale-data/fr.js';

import enTranslationMessages from './translations/en.json';
import deTranslationMessages from './translations/de.json';
import ptTranslationMessages from './translations/pt.json';
import seTranslationMessages from './translations/se.json';
import fiTranslationMessages from './translations/fi.json';
import heTranslationMessages from './translations/he.json';
import esTranslationMessages from './translations/es.json';
import slTranslationMessages from './translations/sl.json';
import frTranslationMessages from './translations/fr.json';

import projectList from './containers/App/config/projectList.js';

const project_name = process.env.REACT_APP_PROJECT_NAME;
// const langConfig = projectList['translation'].language['langOptions'];
// const DEFAULT_LOCALE = projectList['translation'].language['defaultLang'];

const langConfig = projectList[project_name].language.langOptions;
const DEFAULT_LOCALE = projectList[project_name].language.defaultLang;

export const appLocales = langConfig;

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages = locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages) : {};
  const formattedMessages = {};
  const messageKeys = Object.keys(messages);
  for (const messageKey of messageKeys) {
    if (locale === DEFAULT_LOCALE) {
      formattedMessages[messageKey] = messages[messageKey];
    } else {
      formattedMessages[messageKey] = messages[messageKey] || defaultFormattedMessages[messageKey];
    }
  }

  return formattedMessages;
};

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  et: formatTranslationMessages('et', enTranslationMessages),
  de: formatTranslationMessages('de', deTranslationMessages),
  pt: formatTranslationMessages('pt', ptTranslationMessages),
  se: formatTranslationMessages('se', seTranslationMessages),
  fi: formatTranslationMessages('fi', fiTranslationMessages),
  he: formatTranslationMessages('he', heTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
  sl: formatTranslationMessages('sl', slTranslationMessages),
  fr: formatTranslationMessages('fr', frTranslationMessages)
};

const i18n = {};
i18n.appLocales = appLocales;
i18n.formatTranslationMessages = formatTranslationMessages;
i18n.translationMessages = translationMessages;
i18n.DEFAULT_LOCALE = DEFAULT_LOCALE;

export default i18n;
