/* List of projects using the same origin App */

const projectList = {
  translation: {
    name: 'translation',
    language: { defaultLang: 'en', langOptions: ['en', 'fi', 'pt', 'se', 'he', 'es', 'de', 'sl'] }
  },
  ante: {
    name: 'ante',
    language: { defaultLang: 'de', langOptions: ['en', 'de'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  arolla: {
    name: 'arolla',
    language: { defaultLang: 'pt', langOptions: ['en', 'pt'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  sodra: {
    name: 'sodra',
    language: { defaultLang: 'se', langOptions: ['en', 'se'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  bruno: {
    name: 'bruno',
    language: { defaultLang: 'de', langOptions: ['en', 'de'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  mm2: {
    name: 'mm2',
    language: { defaultLang: 'en', langOptions: ['en', 'de'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  schneider: {
    name: 'schneider',
    language: { defaultLang: 'en', langOptions: ['en', 'de'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  arboreal: {
    name: 'arboreal',
    language: { defaultLang: 'es', langOptions: ['es', 'en'] },
    loginInformation: {
      useStandardLogin: false,
      username: 'admin',
      password: 'admin'
    }
  },
  sepa: {
    name: 'sepa',
    language: { defaultLang: 'en', langOptions: ['en', 'fi'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'ledinek'
    }
  },
  eztiles: {
    name: 'eztiles',
    language: { defaultLang: 'en', langOptions: ['en', 'he'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  mosser: {
    name: 'mosser',
    language: { defaultLang: 'en', langOptions: ['en', 'de'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  kafe: {
    name: 'kafe',
    language: { defaultLang: 'en', langOptions: ['en'] }
  },
  baseProject: {
    name: 'baseProject',
    language: { defaultLang: 'en', langOptions: ['en'] }
  },
  weihag: {
    name: 'weihag',
    language: { defaultLang: 'en', langOptions: ['en', 'de'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  solis: {
    name: 'solis',
    language: { defaultLang: 'en', langOptions: ['en', 'sl'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  schilliger: {
    name: 'schilliger',
    language: { defaultLang: 'en', langOptions: ['en', 'de', 'fr'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  smartlam: {
    name: 'smartlam',
    language: { defaultLang: 'en', langOptions: ['en'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  jv20: {
    name: 'jv20',
    language: { defaultLang: 'en', langOptions: ['en', 'es'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  peetri: {
    name: 'peetri',
    language: { defaultLang: 'en', langOptions: ['en', 'et'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  gaishorn2: {
    name: 'gaishorn2',
    language: { defaultLang: 'en', langOptions: ['en', 'de'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  moelven: {
    name: 'moelven',
    language: { defaultLang: 'en', langOptions: ['en'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  lehmann: {
    name: 'lehmann',
    language: { defaultLang: 'en', langOptions: ['en', 'es'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  },
  rigidply: {
    name: 'rigidply',
    language: { defaultLang: 'en', langOptions: ['en'] },
    loginInformation: {
      useStandardLogin: true,
      username: 'admin',
      password: 'admin'
    }
  }
  // Do not delete or modify this line.
};

export default projectList;
