/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_REPOS = 'boilerplate/App/LOAD_REPOS';
export const LOAD_REPOS_SUCCESS = 'boilerplate/App/LOAD_REPOS_SUCCESS';
export const LOAD_REPOS_ERROR = 'boilerplate/App/LOAD_REPOS_ERROR';
export const SET_PROJECT_CONFIG = 'boilerplate/App/SET_PROJECT_CONFIG';
export const SET_ARCHIVE_MODE = 'boilerplate/App/SET_ARCHIVE_MODE';
export const SET_ARCHIVE_MODE_DATE = 'boilerplate/App/SET_ARCHIVE_MODE_DATE';

/* Login */
export const DEFAULT_ACTION = 'app/App/DEFAULT_ACTION';
export const CHANGE_FORM = 'app/App/CHANGE_FORM';
export const SET_AUTH = 'app/App/SET_AUTH';
export const SENDING_REQUEST = 'app/App/SENDING_REQUEST';
export const LOGIN_REQUEST = 'app/App/LOGIN_REQUEST';
export const REQUEST_ERROR = 'app/App/REQUEST_ERROR';
export const CLEAR_ERROR = 'app/App/CLEAR_ERROR';
export const LOGIN_SUCCESS = 'app/App/LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'app/App/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/App/LOGOUT_SUCCESS';
export const AUTH_REQUEST_RESOLVED = 'app/App/AUTH_REQUEST_RESOLVED';
export const SET_SETTINGS = 'app/App/SET_SETTINGS';
export const UPDATE_APP_HASH = 'app/App/UPDATE_APP_HASH';

/* Keyboard */
export const SHOW_KEYBOARD = 'app/App/SHOW_KEYBOARD';
export const SET_INPUT_KEYBOARD = 'app/App/SET_INPUT_KEYBOARD';
export const SET_UPPERCASE_KEYBOARD = 'app/App/SET_UPPERCASE_KEYBOARD';

export const DEFAULT_LOCALE = 'en';
