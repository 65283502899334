/**
 *
 * GridBox
 *
 */

import { memo, useRef, useState, useLayoutEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import styled from 'styled-components';
import './test.css';
import Paper from '@material-ui/core/Paper';
import Button from '../../../Button';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { MoreIcon } from '../../../reusable/Icons';
import { useHistory } from 'react-router-dom';
import FormattedMessage from '../../../TranslationWrapper';
import globalMessages from '../../../../globalMessages';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import StatusItem from '../../../StatusItem';

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

const EtageBox = styled.div`
  font-size: 8px;
  font-weight: lighter;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: white;
  // ... other styles
`;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={1000} />;
});

export function GridBox(props) {
  // eslint-disable-line react/prefer-stateless-function

  const history = useHistory();
  const imgUrl = _.get(props, 'configData.background', '');

  const backgroundImageStyle = {
    backgroundImage: 'url(/' + imgUrl + ')'
  };
  const ref = useRef(null);
  const background = useRef(null);
  const [backgroundHeight, setBackgroundHeight] = useState(0);
  const [backgroundWidth, setBackgroundWidth] = useState(0);
  const [diff, setDiff] = useState(0);
  const [verticalDiff, setVerticalDiff] = useState(0);
  const backgroundRatio = _.get(props, 'configData.ratio', 1); // width/height
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  useLayoutEffect(() => {
    setBackgroundWidth(ref.current.offsetWidth);
    setBackgroundHeight(ref.current.offsetHeight);
    if (ref.current.clientWidth / ref.current.clientHeight > backgroundRatio) {
      setDiff(background.current.clientWidth - background.current.clientHeight * backgroundRatio);
      setVerticalDiff(0);
    } else {
      setDiff(0);
      setVerticalDiff((backgroundHeight - backgroundWidth / backgroundRatio) / 2);
    }
  }, [ref?.current?.offsetWidth]);

  function getItem(i, j, cv) {
    const identifier = _.get(props, 'configData.elements' + '[' + i + '-' + j + ']', null);
    const item = _.get(props, 'productionstatus.' + identifier, null);
    if (item != null && _.get(item, 'id') > 0) {
      const itemId = _.get(item, 'id');
      const lamellaThickness = _.get(item, 'lamellaThickness');
      const lamellaWidth = _.get(item, 'lamellaWidth');
      const lamellaLength = _.get(item, 'lamellaLength');
      const tooltipText = `${identifier} \n ${` `} \n ID: ${itemId}, Dimensions: ${lamellaThickness} x ${lamellaWidth} x ${lamellaLength} mm`;
      const tooltipLines = tooltipText.split('\n').map((line, index) => <div key={index}>{line}</div>);

      return (
        <EtageBox
          onClick={() => {
            setDialogContent(tooltipLines);
            setDialogOpen(true);
          }}
          style={{
            backgroundColor: _.get(item, cv, null) !== null && _.get(item, cv) !== '' ? _.get(item, cv) : 'rgba(255,195,0,75)',
            color: 'white'
          }}></EtageBox>
      );
    } else {
      return null;
    }
  }

  // status items
  const booleansLeft = _.get(props, 'configData.statusBooleans[0]', []);
  const booleansRight = _.get(props, 'configData.statusBooleans[1]', []);

  function getStatusItems(items) {
    return items.map((item, index) => (
      <StatusItem item={_.get(props.productionstatus, item, false)} type="green" key={index}>
        {/* <FormattedMessage {...(globalMessages + '.' + item)} /> */}
        <FormattedMessage id={item} defaultMessage={item} />
      </StatusItem>
    ));
  }

  function generateElements() {
    const arrayWidth = _.get(props, 'configData.arrayWidth', 0);
    const arrayHeight = _.get(props, 'configData.arrayHeight', 0);
    // let spacing = _.get(props, 'configData.spacing', 0);
    // let colorVariable = _.get(props, 'configData.colorVariable', '');
    const colorVariable = 'orderColor';
    const view = [];
    // let widerObjects = _.get(props, 'configData.widerObjects', false);
    if (arrayWidth > 0 && arrayHeight > 0) {
      const elementWidth = (backgroundWidth - diff) / arrayWidth;
      const elementHeight = (backgroundWidth - diff) / backgroundRatio / arrayHeight;

      return (
        <div className="container">
          {Array.from({ length: arrayHeight }, (item, i) => (
            <div key={`row-${i}`} className="row" style={{ height: elementHeight + 'px' }}>
              {Array.from({ length: arrayWidth }, (item, j) => (
                <div key={`col-${i}-${j}`} className="col-double-width">
                  {getItem(i, j, colorVariable)}
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }

    return view;
  }

  return (
    <Wrapper ref={ref}>
      <div style={{ height: verticalDiff * 0.5 + 'px' }} />
      <div style={{ width: backgroundWidth - diff + 'px', height: (backgroundWidth - diff) / backgroundRatio + 'px' }}>
        <div ref={background} className="background" style={backgroundImageStyle}>
          <div className="elements" style={{ width: backgroundWidth - diff + 'px', marginLeft: diff / 2 + 'px', height: (backgroundWidth - diff) / backgroundRatio + 'px' }}>
            {generateElements()}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '45%' }}>{getStatusItems(booleansLeft)}</div>
          <div style={{ marginLeft: '10%', width: '45%' }}>{getStatusItems(booleansRight)}</div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          width: backgroundWidth - diff + 'px',
          bottom: '10px',
          alignItems: 'center',
          flexWrap: 'wrap',
          verticalAlign: 'middle',
          justifyContent: 'center',
          display: 'inline-flex'
        }}>
        {_.get(props, 'configData.path', false) && (
          <Button
            onClick={() =>
              history.push({
                pathname: _.get(props, 'configData.path', false),
                props: props.productionstatus
              })
            }>
            <MoreIcon className="prodlineMore" style={{ width: '2rem', height: '2rem' }} />
          </Button>
        )}
      </div>
      <Dialog open={dialogOpen} TransitionComponent={Transition} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          <FormattedMessage {...globalMessages.packageInfo} identifier={'packageInfo'} />
        </DialogTitle>
        <StyledDialogContent>{dialogContent}</StyledDialogContent>
      </Dialog>
    </Wrapper>
  );
}
GridBox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  productionstatus: PropTypes.object
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(GridBox);
