import globalMessages from '../../globalMessages';
import { MODEL_LOADED, MODEL_SAVED, CONFIG_LOADED, CLEAR_RESPONSE, ORDER_NUMEBR_GENERATED } from './constants';
import produce from 'immer';
import { generalValidator, planerSetupValidator } from '../../utils/modelFunctions/validators';
import { orderChildCalculateValues } from '../../utils/modelFunctions/calculators';
import projectList from './config/editviewList';
import _ from 'lodash';

const bshLayerFields = projectList?.default?.bshLayerFields;
const orderFields = projectList?.default?.orderFields;
const recipeOrderFields = projectList?.default?.recipeOrderFields;
const incomingPackageFields = projectList?.default?.incomingPackageFields;
const bshorderFields = projectList?.default?.bshorderFields;
const beamFields = projectList?.default?.beamFields;
const beamLayerFields = projectList?.default?.beamLayerFields;
const projectFields = projectList?.default?.projectFields;
const projectOrderFields = projectList?.default?.projectOrderFields;
const plateFields = projectList?.default?.plateFields;
const layerFields = projectList?.default?.layerFields;
const recipeLayerFields = projectList?.default?.recipeLayerFields;
const orderWithRecipeFields = projectList?.default?.orderWithRecipeFields;
const projectWithRecipeFields = projectList?.default?.projectWithRecipeFields;
const orderLayerRecipeFields = projectList?.default?.orderLayerRecipeFields;
const layerWithRecipeFields = projectList?.default?.layerWithRecipeFields;
const planerSetupFields = projectList?.default?.planerSetupFields;
const extraLamellaEntryFields = projectList?.default?.extraLamellaEntryFields;
const productFields = projectList?.default?.productFields;
const supplierFields = projectList?.default?.supplierFields;
const cltPackageFields = projectList?.default?.cltPackageFields;
const machineSettingFields = projectList?.default?.machineSettings;

export const initialState = {
  config: null,
  title: globalMessages.addUser,
  response: null,
  loading: true,
  item: null,
  initialItem: null,
  setFields: null,
  itemDefault: {
    client: '',
    colsNumber: 0,
    isKVH: false,
    isActive: false,
    jobCode: '',
    lamellaLength: 0,
    lamellaThickness: 0,
    lamellaWidth: 0,
    layerNumber: 0,
    layers: [],
    numOfLamellas: 0,
    numOfLamellasWood: 0,
    numOfLamellasIns: 0,
    orderCharge: '',
    orderNumber: '',
    orderWidth: 0,
    oversize: 0,
    oversizeWidth: 0,
    panelLength: 0,
    panelThickness: 0,
    panelWidth: 0,
    quality: '',
    rawThickness: 0,
    rawWidth: 0,
    orientation: 1,
    beamWidth: []
  },
  form: {
    api: {
      url: '/api/v1/orders/all/',
      type: 'POST'
    },
    modelLoaded: false,
    childFields: null,
    defaultValues: {
      orderNumber: Math.floor(Date.now() / 60000),
      separatorDistance: 4,
      oversize: 20,
      oversizeWidth: 60
    },
    defaultLayerValues: {
      oversize: 20,
      oversizeWidth: 20,
      minTakeAway: 20,
      maxTakeaway: 150,
      widthoffset: [],
      lengthoffset: [],
      widthoffsetrecipe: [],
      lengthoffsetrecipe: [],
      fixedRecipeLayer: false,
      hollow: false,
      numOfLamellasWood: 0,
      numOfLamellasIns: 0,
      beamWidth: [],
      lengthoffsetWidth: 0
    }
  }
};

function sortLayers(layers) {
  const sortingLayers = layers;
  let l;
  let m;

  if (sortingLayers !== undefined && sortingLayers !== null && Array.isArray(sortingLayers)) {
    l = [...sortingLayers].sort((a, b) => (a.layerNo < b.layerNo ? -1 : 1));
  }

  // Sortiranje offsetov po vrsti (idji vcasih pomesani)
  for (let i = 0; i < l.length; i++) {
    if (l[i]?.widthoffset != undefined && l[i]?.widthoffset.length > 0) {
      l[i].widthoffset = sortWidthOffset(l[i].widthoffset);
    }
  }

  for (let i = 0; i < l.length; i++) {
    if (l[i]?.lengthoffset != undefined && l[i]?.lengthoffset.length > 0) {
      l[i].lengthoffset = sortLengthOffset(l[i].lengthoffset);
    }
  }

  return l;
}

function sortWidthOffset(widthoffset) {
  const sortingWidthOffset = widthoffset;
  let l;
  if (sortingWidthOffset !== undefined && sortingWidthOffset !== null && Array.isArray(sortingWidthOffset)) {
    l = [...sortingWidthOffset].sort((a, b) => (a.offset < b.offset ? -1 : 1));
  }
  return l;
}

function sortLengthOffset(lengthoffset) {
  const sortingLengthOffset = lengthoffset;
  let m;
  if (sortingLengthOffset !== undefined && sortingLengthOffset !== null && Array.isArray(sortingLengthOffset)) {
    m = [...sortingLengthOffset].sort((a, b) => (a.offset < b.offset ? -1 : 1));
  }
  return m;
}

function setFields(config, draft) {
  switch (config.model) {
    case 'order':
      draft.form.fields = orderFields;
      draft.form.childFields = layerFields;
      draft.form.modelName = 'order';
      draft.form.childFieldName = 'layers';
      draft.form.childFieldNameSingle = 'layer';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], generalValidator);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], orderChildCalculateValues);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.modelLoaded = true;
      draft.form.countChildrenProp = 'layerNo';
      break;

    case 'bshorder':
      draft.form.fields = bshorderFields;
      draft.form.childFields = bshLayerFields;
      draft.form.modelName = ' bshorder';
      draft.form.childFieldName = 'layers';
      draft.form.childFieldNameSingle = 'layer';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.modelLoaded = true;
      draft.form.countChildrenProp = 'layerNo';
      break;

    case 'layers':
      draft.form.fields = layerFields;
      draft.form.childFields = null;
      draft.form.modelName = 'layer';
      draft.form.childFieldName = null;
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.modelLoaded = true;
      break;

    case 'beam':
      draft.form.fields = beamFields;
      draft.form.childFields = beamLayerFields;
      draft.form.modelName = 'order';
      draft.form.childFieldName = 'layers';
      draft.form.childFieldNameSingle = 'layer';
      draft.form.modelValidator = null;
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      draft.form.countChildrenProp = 'layerNo';
      break;

    case 'recipe':
      draft.form.fields = recipeOrderFields;
      draft.form.childFields = recipeLayerFields;
      draft.form.childFieldName = 'layers';
      draft.form.childFieldNameSingle = 'layer';
      draft.form.modelName = 'order';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);

      draft.form.modelLoaded = true;
      break;

    case 'packages':
      draft.form.fields = incomingPackageFields;
      draft.form.childFields = null;
      draft.form.childFieldName = null;
      draft.form.childFieldNameSingle = null;
      draft.form.modelName = 'packages';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);

      draft.form.modelLoaded = true;
      break;

    case 'project':
      draft.form.fields = projectFields;
      draft.form.childFields = projectOrderFields;
      draft.form.childFieldName = 'orders';
      draft.form.childFieldNameSingle = 'order';
      draft.form.modelName = 'project';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'cltPackage':
      draft.form.fields = cltPackageFields;
      draft.form.childFields = null;
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = '';
      draft.form.modelName = 'cltPackage';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'machineSetting':
      draft.form.fields = machineSettingFields;
      draft.form.childFields = null;
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = '';
      draft.form.modelName = 'machineSetting';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'orderWithRecipe':
      draft.form.fields = orderWithRecipeFields;
      draft.form.childFields = null;
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = '';
      draft.form.modelName = 'orderWithRecipe';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'projectWithRecipe':
      draft.form.fields = projectWithRecipeFields;
      draft.form.childFields = null;
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = '';
      draft.form.modelName = 'projectWithRecipe';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'layerWithRecipe':
      draft.form.fields = orderLayerRecipeFields;
      draft.form.childFields = layerWithRecipeFields;
      draft.form.childFieldName = 'recipeLayer';
      draft.form.childFieldNameSingle = 'id';
      draft.form.modelName = 'layerWithRecipe';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'arollaPlates':
      draft.form.fields = plateFields;
      draft.form.childFields = null;
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = '';
      draft.form.modelName = 'ProjectPlate';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'product':
      draft.form.fields = productFields;
      draft.form.childFields = null;
      draft.form.modelName = 'product';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.modelLoaded = true;
      break;

    case 'supplier':
      draft.form.fields = supplierFields;
      draft.form.childFields = null;
      draft.form.modelName = 'supplier';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.modelLoaded = true;
      break;

    case 'cltpackage':
      draft.form.fields = orderFields;
      draft.form.childFields = layerFields;
      draft.form.modelName = 'order';
      draft.form.childFieldName = 'layers';
      draft.form.childFieldNameSingle = 'layer';
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.modelLoaded = true;
      draft.form.countChildrenProp = 'layerNo';
      break;

    case 'erp':
      draft.form.fields = _.get(projectList, ['default', 'erpFields'], []);
      draft.form.childFields = null;
      draft.form.modelName = 'erp';
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = null;
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.modelLoaded = true;
      break;

    case 'planerSetup':
      draft.form.fields = planerSetupFields;
      draft.form.childFields = null;
      draft.form.modelName = 'planerSetup';
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = null;
      draft.form.modelValidator = planerSetupValidator;
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.options = _.get(projectList, ['default', 'otherOptions', config.model], null);
      draft.form.modelLoaded = true;
      break;

    case 'extraLamellaEntry':
      draft.form.fields = extraLamellaEntryFields;
      draft.form.childFields = null;
      draft.form.modelName = 'extraLamellaEntry';
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = null;
      draft.form.modelValidator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelValidator'], null);
      draft.form.childCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'childCalculator'], null);
      draft.form.modelCalculator = _.get(projectList, ['default', 'calculatorsConfig', config.model, 'modelCalculator'], null);
      draft.form.childCalculator = null;
      draft.form.options = _.get(projectList, ['default', 'otherOptions', config.model], null);
      draft.form.modelLoaded = true;
      break;

    default:
      draft.form.fields = [];
      draft.form.childFields = null;
      draft.form.childFieldName = '';
      draft.form.childFieldNameSingle = '';
      draft.form.modelName = '';
      draft.form.modelValidator = null;
      draft.form.childCalculator = null;
      draft.form.modelCalculator = null;
      draft.form.childCalculator = null;
      draft.form.modelLoaded = false;
      break;
  }
}

const editViewReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MODEL_SAVED:
        draft.response = action.response;
        break;
      case CLEAR_RESPONSE:
        draft.response = null;
        break;
      case CONFIG_LOADED:
        draft.config = action.config;
        setFields(action.config, draft);
        break;
      case ORDER_NUMEBR_GENERATED:
        draft.form.defaultValues.orderNumber = action.time;
        break;
      case MODEL_LOADED: {
        draft.item = action.response;
        if (draft.item?.layers) draft.item.layers = sortLayers(action.response.layers);
        draft.initialItem = draft.item;
        draft.loading = false;
        break;
      }
      default:
        return state;
    }
  });

export default editViewReducer;
