/*
 * ProductionLine Messages
 *
 * This contains all the text for the ProductionLine component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  orderIdInfo: {
    id: 'app.containers.modelFieldMessages.orderIdInfo',
    defaultMessage: 'This is an id of the order'
  },
  recalculateOrder: {
    id: 'app.containers.modelFieldMessages.recalculateOrder',
    defaultMessage: 'Recalc'
  },
  manageServer: {
    id: 'app.containers.productionline.manageServer',
    defaultMessage: 'Manage Server'
  },
  isCentral: {
    id: 'app.containers.productionline.isCentral',
    defaultMessage: 'Central Server'
  },
  bshCombineLength: {
    id: 'app.containers.productionline.bshCombineLength',
    defaultMessage: 'BSH Combine Length'
  },
  finishingNotes: {
    id: 'app.containers.editView.finishingNotes',
    defaultMessage: 'Finishing Notes'
  },
  truck: {
    id: 'app.containers.editView.truck',
    defaultMessage: 'Truck'
  },
  surfacing: {
    id: 'app.containers.editView.surfacing',
    defaultMessage: 'Surfacing'
  },
  structuralReference: {
    id: 'app.containers.editView.structuralReference',
    defaultMessage: 'Stuctural Reference'
  },
  moveUp: {
    id: 'app.containers.editView.moveUp',
    defaultMessage: 'Move Up'
  },
  moveDown: {
    id: 'app.containers.editView.moveDown',
    defaultMessage: 'Move Down'
  },
  ripBeam: {
    id: 'app.containers.editView.ripBeam',
    defaultMessage: 'Rip Beam'
  },
  projectId: {
    id: 'app.containers.editView.projectId',
    defaultMessage: 'Project ID'
  },
  production: {
    id: 'app.containers.editView.production',
    defaultMessage: 'Production'
  },
  fireRating: {
    id: 'app.containers.editView.fireRating',
    defaultMessage: 'Fire Rating'
  },
  oAuthApiTest: {
    id: 'app.containers.ModelFieldMessages.oAuthApiTest',
    defaultMessage: 'API test'
  },
  fjApiTest: {
    id: 'app.containers.ModelFieldMessages.fjApiTest',
    defaultMessage: 'FJ API test'
  },
  assignDimensionsToOrder: {
    id: 'app.containers.ModelFieldMessages.assignDimensionsToOrder',
    defaultMessage: 'Assign Dimensions'
  },
  apply: {
    id: 'app.containers',
    defaultMessage: 'Apply'
  },
  selectGroup: {
    id: 'app.containers.selectGroup',
    defaultMessage: 'Select Group'
  },
  selectPrinter: {
    id: 'app.containers.selectPrinter',
    defaultMessage: 'Select Printer'
  },
  brokenLayer: {
    id: 'app.containers.ModelFieldMessages.brokenLayer',
    defaultMessage: 'Broken Layer'
  },
  addFilter: {
    id: 'app.containers.Products.addFilter',
    defaultMessage: 'Add Filter'
  },
  sendToZink: {
    id: 'app.containers.Products.sendToZink',
    defaultMessage: 'Make Lamellas'
  },
  newFilter: {
    id: 'app.containers.Products.newFilter',
    defaultMessage: 'New Filter'
  },
  popFilter: {
    id: 'app.containers.Products.popFilter',
    defaultMessage: 'Pop Filter'
  },
  bottomCut: {
    id: 'app.containers.Products.bottomCut',
    defaultMessage: 'Bottom Cut'
  },
  bandsaw: {
    id: 'app.containers.Products.bandsaw',
    defaultMessage: 'Bandsaw'
  },
  fromStrapMachine: {
    id: 'app.containers.Products.fromStrapMachine',
    defaultMessage: 'FromStrapMachine'
  },
  comment: {
    id: 'app.containers.Products.comment',
    defaultMessage: 'comment'
  },
  ip: {
    id: 'app.containers.Products.ip',
    defaultMessage: 'IP'
  },
  sawmillSelection: {
    id: 'app.containers.Products.sawmillSelection',
    defaultMessage: 'Sawmill Select'
  },
  joinedDimensionId: {
    id: 'app.containers.ModelFieldMessages.joinedDimensionId',
    defaultMessage: 'Joined dim. ID'
  },
  orderCharge: {
    id: 'app.containers.ModelFieldMessages.orderCharge',
    defaultMessage: 'Charge'
  },
  salesOrderNumber: {
    id: 'app.containers.ModelFieldMessages.salesOrderNumber',
    defaultMessage: 'Sales N°'
  },
  projectName_schilliger: {
    id: 'app.containers.ModelFieldMessages.projectName_schilliger',
    defaultMessage: 'Order Number'
  },
  layerId: {
    id: 'app.containers.ModelFieldMessages.layerId',
    defaultMessage: 'Layer Id'
  },
  startOrder: {
    id: 'app.containers.ModelFieldMessages.start',
    defaultMessage: 'Start'
  },
  scannerStatus: {
    id: 'app.containers.ModelFieldMessages.scannerStatus',
    defaultMessage: 'Scanner Status'
  },
  stackingNumOfLayers: {
    id: 'app.containers.ModelFieldMessages.stackingNumOfLayers',
    defaultMessage: 'Stack No.Layers'
  },
  stackingName: {
    id: 'app.containers.ModelFieldMessages.stackingName',
    defaultMessage: 'Stacking Name'
  },
  stackingLamellasPerLayer: {
    id: 'app.containers.ModelFieldMessages.stackingLamellasPerLayer',
    defaultMessage: 'Stack Lam/Layer'
  },
  stacker: {
    id: 'app.containers.Products.stacker',
    defaultMessage: 'Stacker'
  },
  check: {
    id: 'app.containers.Products.check',
    defaultMessage: ' x '
  },
  packetSize: {
    id: 'app.containers.Products.packetSize',
    defaultMessage: 'Packet Size'
  },
  stickLayerNumbers: {
    id: 'app.containers.Products.stickLayerNumbers',
    defaultMessage: 'Stick Layer Numbers'
  },
  piecesUsed: {
    id: 'app.containers.editview.piecesUsed',
    defaultMessage: 'Pieces Used'
  },
  stackingOrientation: {
    id: 'app.containers.Products.stackingOrientation',
    defaultMessage: 'Stacking Orientation'
  },
  numOfLamellas: {
    id: 'app.containers.ModelFieldMessages.numOfLamellas',
    defaultMessage: 'Lamellas'
  },
  numberOfWidths: {
    id: 'app.containers.ModelFieldMessages.numberOfWidths',
    defaultMessage: 'No. Widths'
  },
  moscaNumberOfLayers: {
    id: 'app.containers.ModelFieldMessages.moscaNumberOfLayers',
    defaultMessage: 'Mosca Number Of Layers'
  },
  moscaNumOfLamellas: {
    id: 'app.containers.ModelFieldMessages.moscaNumOfLamellas',
    defaultMessage: 'Mosca Num Of Lamellas'
  },
  lengthHeadCut: {
    id: 'app.containers.ModelFieldMessages.lengthHeadCut',
    defaultMessage: 'Head Cut'
  },
  moscaNumberOfBoards: {
    id: 'app.containers.ModelFieldMessages.moscaNumberOfBoards',
    defaultMessage: 'Mosca Number Of Boards'
  },
  lengthMiddleCut1: {
    id: 'app.containers.ModelFieldMessages.lengthMiddleCut1',
    defaultMessage: 'MiddleCut 1'
  },
  lengthMiddleCut2: {
    id: 'app.containers.ModelFieldMessages.lengthMiddleCut2',
    defaultMessage: 'MiddleCut 2'
  },
  numOfPackages: {
    id: 'app.containers.ModelFieldMessages.numOfPackages',
    defaultMessage: 'Number of packages'
  },
  paNr: {
    id: 'app.containers.ModelFieldMessages.paNr',
    defaultMessage: 'paNr'
  },
  id: {
    id: 'app.containers.ModelFieldMessages.id',
    defaultMessage: 'ID'
  },
  number: {
    id: 'app.containers.ModelFieldMessages.number',
    defaultMessage: 'Schill. ID'
  },
  identifier: {
    id: 'app.containers.ModelFieldMessages.identifier',
    defaultMessage: 'Identifier'
  },
  identifierSchneider: {
    id: 'app.containers.ModelFieldMessages.identifierSchneider',
    defaultMessage: 'Paked ID HLR1'
  },
  lastSeen: {
    id: 'app.containers.ModelFieldMessages.lastSeen',
    defaultMessage: 'Last Seen'
  },
  firstSeen: {
    id: 'app.containers.ModelFieldMessages.firstSeen',
    defaultMessage: 'First Seen'
  },
  threshold: {
    id: 'app.containers.ModelFieldMessages.threshold',
    defaultMessage: 'Threshold'
  },
  severity: {
    id: 'app.containers.ModelFieldMessages.severity',
    defaultMessage: 'Severity'
  },
  layerIdentifier: {
    id: 'app.containers.ModelFieldMessages.layerIdentifier',
    defaultMessage: 'Layer Identifier'
  },
  erpId: {
    id: 'app.containers.ModelFieldMessages.erpId',
    defaultMessage: 'Erp ID'
  },
  supplierIndex: {
    id: 'app.containers.ModelFieldMessages.supplierIndex',
    defaultMessage: 'Supplier Index'
  },
  pressId: {
    id: 'app.containers.ModelFieldMessages.pressId',
    defaultMessage: 'pressId'
  },
  xmPressId: {
    id: 'app.containers.ModelFieldMessages.xmPressId',
    defaultMessage: 'xmPressId'
  },
  compartment: {
    id: 'app.containers.ModelFieldMessages.compartment',
    defaultMessage: 'Compartment'
  },
  glueOpenTime: {
    id: 'app.containers.ModelFieldMessages.glueOpenTime',
    defaultMessage: 'Glue open time (min)'
  },
  glueOpenTimeSec: {
    id: 'app.containers.ModelFieldMessages.glueOpenTime',
    defaultMessage: 'Glue open time (s)'
  },
  glueTemp: {
    id: 'app.containers.ModelFieldMessages.glueTemp',
    defaultMessage: 'Glue temperature (°C)'
  },
  pressTime: {
    id: 'app.containers.ModelFieldMessages.pressTime',
    defaultMessage: 'Press time (min)'
  },
  piecesPerLayer: {
    id: 'app.containers.ModelFieldMessages.piecesPerLayer',
    defaultMessage: 'Pieces per layer'
  },
  status: {
    id: 'app.containers.ModelFieldMessages.status',
    defaultMessage: 'Status'
  },
  usage: {
    id: 'app.containers.ModelFieldMessages.usage',
    defaultMessage: 'Usage'
  },
  pressureAvgFinal: {
    id: 'app.containers.ModelFieldMessages.pressureAvgFinal',
    defaultMessage: 'Avg. pressure (bar)'
  },
  neededOnLoadingPosition: {
    id: 'app.containers.ModelFieldMessages.neededOnLoadingPosition',
    defaultMessage: 'Load on position'
  },
  length: {
    id: 'app.containers.ModelFieldMessages.length',
    defaultMessage: 'Piece length'
  },
  order: {
    id: 'app.containers.ModelFieldMessages.order',
    defaultMessage: 'Order'
  },
  lm: {
    id: 'app.containers.ModelFieldMessages.lm',
    defaultMessage: 'Total lm needed'
  },
  lmWithPaketladn: {
    id: 'app.containers.ModelFieldMessages.lmWithPaketladn',
    defaultMessage: 'Total lm needed (with package loading)'
  },
  lmWithOrderedPackages: {
    id: 'app.containers.ModelFieldMessages.lmWithOrderedPackages',
    defaultMessage: 'Total lm needed (with loading/orders)'
  },
  lmUsed: {
    id: 'app.containers.ModelFieldMessages.lmUsed',
    defaultMessage: 'Total lm used'
  },
  glueAmount: {
    id: 'app.containers.ModelFieldMessages.glueAmount',
    defaultMessage: 'Glue amount'
  },
  type: {
    id: 'app.containers.ModelFieldMessages.type',
    defaultMessage: 'Type'
  },
  errorId: {
    id: 'app.containers.ModelFieldMessages.errorId',
    defaultMessage: 'Error ID'
  },
  lotNumber: {
    id: 'app.containers.ModelFieldMessages.lotNumber',
    defaultMessage: 'Lot number'
  },
  storage: {
    id: 'app.containers.ModelFieldMessages.storage',
    defaultMessage: 'storage'
  },
  packageNumber: {
    id: 'app.containers.ModelFieldMessages.packageNumber',
    defaultMessage: 'Package Number'
  },
  erpDate: {
    id: 'app.containers.ModelFieldMessages.erpDate',
    defaultMessage: 'Production date'
  },
  shouldBeMovedToOtherLine: {
    id: 'app.containers.ModelFieldMessages.shouldBeMovedToOtherLine',
    defaultMessage: 'Marked to move'
  },
  lockedLamellaDimensions: {
    id: 'app.containers.ModelFieldMessages.lockedLamellaDimensions',
    defaultMessage: 'Locked incoming package dimensions (+5 mm)'
  },
  qPackageType: {
    id: 'app.containers.ModelFieldMessages.qPackageType',
    defaultMessage: 'Q'
  },
  iPackageType: {
    id: 'app.containers.ModelFieldMessages.iPackageType',
    defaultMessage: 'I'
  },
  lPackageType: {
    id: 'app.containers.ModelFieldMessages.lPackageType',
    defaultMessage: 'L'
  },
  ilPackageType: {
    id: 'app.containers.ModelFieldMessages.ilPackageType',
    defaultMessage: 'L-I'
  },
  mPackageType: {
    id: 'app.containers.ModelFieldMessages.mPackageType',
    defaultMessage: 'M'
  },
  iqPackageType: {
    id: 'app.containers.ModelFieldMessages.iqPackageType',
    defaultMessage: 'Q-I'
  },
  KVHPackageType: {
    id: 'app.containers.ModelFieldMessages.KVHPackageType',
    defaultMessage: 'KVH'
  },
  orderNumber: {
    id: 'app.containers.ModelFieldMessages.orderNumber',
    defaultMessage: 'Order number'
  },
  orderNumber: {
    id: 'app.containers.ModelFieldMessages.orderNumber',
    defaultMessage: 'Order number'
  },
  jobNumber: {
    id: 'app.containers.ModelFieldMessages.jobNumber',
    defaultMessage: 'job number'
  },
  inputArticle: {
    id: 'app.containers.ModelFieldMessages.inputArticle',
    defaultMessage: 'Article'
  },
  chargeNumber: {
    id: 'app.containers.ModelFieldMessages.chargeNumber',
    defaultMessage: 'Charge number'
  },
  smartlamOrderNumber: {
    id: 'app.containers.ModelFieldMessages.smartlamOrderNumber',
    defaultMessage: 'Press Number'
  },
  orderImportNumber: {
    id: 'app.containers.ModelFieldMessages.orderImportNumber',
    defaultMessage: 'Order number'
  },
  orders: {
    id: 'app.containers.ModelFieldMessages.orders',
    defaultMessage: 'Orders'
  },
  ordersClient: {
    id: 'app.containers.ModelFieldMessages.ordersClient',
    defaultMessage: 'Piveteau ID'
  },
  numLamellas: {
    id: 'app.containers.ModelFieldMessages.numLamellas',
    defaultMessage: 'No. lam.'
  },
  numberOfBeams: {
    id: 'app.containers.ModelFieldMessages.numberOfBeams',
    defaultMessage: 'No. of beams'
  },
  numOfLamellasWood: {
    id: 'app.containers.ModelFieldMessages.numOfLamellasWood',
    defaultMessage: 'No. lam. wood'
  },
  numOfLamellasIns: {
    id: 'app.containers.ModelFieldMessages.numOfLamellasIns',
    defaultMessage: 'No. lam. Insulation'
  },
  multipieceWidth: {
    id: 'app.containers.ModelFieldMessages.multipieceWidth',
    defaultMessage: 'Multipiece Raw Width'
  },
  zincDoneNumLamellas: {
    id: 'app.containers.ModelFieldMessages.zincDoneNumLamellas',
    defaultMessage: 'Zinc done'
  },
  zincDoneNumLamellasSchneider: {
    id: 'app.containers.ModelFieldMessages.zincDoneNumLamellasSchneider',
    defaultMessage: 'Lamellen ist'
  },
  numLamellasWithExtraSchneider: {
    id: 'app.containers.ModelFieldMessages.numLamellasWithExtraSchneider',
    defaultMessage: 'Lamellen soll'
  },
  planerDoneNumLamellas: {
    id: 'app.containers.ModelFieldMessages.planerDoneNumLamellas',
    defaultMessage: 'Planer done'
  },
  stackerDoneNumLamellas: {
    id: 'app.containers.ModelFieldMessages.stackerDoneNumLamellas',
    defaultMessage: 'Stacker done'
  },
  insRawZincDoneNumLamellas: {
    id: 'app.containers.ModelFieldMessages.insRawZincDoneNumLamellas',
    defaultMessage: 'INS Raw Cut'
  },
  lineNumber: {
    id: 'app.containers.ModelFieldMessages.lineNumber',
    defaultMessage: 'Line'
  },
  priority: {
    id: 'app.containers.ModelFieldMessages.priority',
    defaultMessage: 'Priority'
  },
  priorityUp: {
    id: 'app.containers.ModelFieldMessages.priorityUp',
    defaultMessage: 'Priority UP'
  },
  priorityDown: {
    id: 'app.containers.ModelFieldMessages.priorityDown',
    defaultMessage: 'Priority DOWN'
  },
  lamellaWidth: {
    id: 'app.containers.ModelFieldMessages.lamellaWidth',
    defaultMessage: 'Width (mm)'
  },
  lamellaWidthActual: {
    id: 'app.containers.ModelFieldMessages.lamellaWidthActual',
    defaultMessage: 'Lamella width (mm)'
  },
  oversize: {
    id: 'app.containers.ModelFieldMessages.oversize',
    defaultMessage: 'Pre-press oversize L (mm)'
  },
  oversizeWidth: {
    id: 'app.containers.ModelFieldMessages.oversizeWidth',
    defaultMessage: 'Pre-press oversize width Q (mm)'
  },
  lamellaPlannedWidth: {
    id: 'app.containers.ModelFieldMessages.lamellaPlannedWidth',
    defaultMessage: 'Raw width (mm)'
  },
  imperialLamellaPlannedWidth: {
    id: 'app.containers.ModelFieldMessages.imperialLamellaPlannedWidth',
    defaultMessage: 'Planned width'
  },
  incomingLamellaWidth: {
    id: 'app.containers.ModelFieldMessages.incomingLamellaWidth',
    defaultMessage: 'Incoming width (+ max 5)'
  },
  lamellaThickness: {
    id: 'app.containers.ModelFieldMessages.lamellaThickness',
    defaultMessage: 'Thickness (mm)'
  },
  lamellaThicknessSchneider: {
    id: 'app.containers.ModelFieldMessages.lamellaThicknessSchneider',
    defaultMessage: 'Thickness (mm)'
  },
  beamLength: {
    id: 'app.containers.ModelFieldMessages.beamLength',
    defaultMessage: 'beamLength (mm)'
  },
  beamWidthRaw: {
    id: 'app.containers.ModelFieldMessages.beamWidthRaw',
    defaultMessage: 'beamWidthRaw (mm)'
  },
  beamThickness: {
    id: 'app.containers.ModelFieldMessages.beamThickness',
    defaultMessage: 'beamThickness (mm)'
  },
  beamWidthFinal: {
    id: 'app.containers.ModelFieldMessages.beamWidthFinal',
    defaultMessage: 'beamWidthFinal (mm)'
  },
  numberOfBeams: {
    id: 'app.containers.ModelFieldMessages.numberOfBeams',
    defaultMessage: 'numberOfBeams (mm)'
  },
  pressWidth: {
    id: 'app.containers.ModelFieldMessages.pressWidth',
    defaultMessage: 'pressWidth (mm)'
  },
  extraLOversize: {
    id: 'app.containers.ModelFieldMessages.extraLOversize',
    defaultMessage: 'L Offset (mm)'
  },
  extraQOversize: {
    id: 'app.containers.ModelFieldMessages.extraQOversize',
    defaultMessage: 'Q Offset (mm)'
  },
  lamellaPlannedThickness: {
    id: 'app.containers.ModelFieldMessages.lamellaPlannedThickness',
    defaultMessage: 'Raw thickness (mm)'
  },
  lamellaPlannedThicknessSchneider: {
    id: 'app.containers.Users.planedDimensions',
    defaultMessage: 'Raw thickness (mm)'
  },
  imperialLamellaPlannedThickness: {
    id: 'app.containers.ModelFieldMessages.imperialLamellaPlannedThickness',
    defaultMessage: 'Planned thickness'
  },
  incomingLamellaThickness: {
    id: 'app.containers.ModelFieldMessages.incomingLamellaThickness',
    defaultMessage: 'Incoming thickness (+ max 5)'
  },
  lamellaLength: {
    id: 'app.containers.ModelFieldMessages.lamellaLength',
    defaultMessage: 'Length (mm)'
  },
  lamellaLength1: {
    id: 'app.containers.ModelFieldMessages.lamellaLength1',
    defaultMessage: 'Length 1 (mm)'
  },
  lamellaLength2: {
    id: 'app.containers.ModelFieldMessages.lamellaLength2',
    defaultMessage: 'Length 2 (mm)'
  },
  lamellaLength3: {
    id: 'app.containers.ModelFieldMessages.lamellaLength3',
    defaultMessage: 'Length 3 (mm)'
  },
  lamellaClass: {
    id: 'app.containers.ModelFieldMessages.lamellaClass',
    defaultMessage: 'Class (e.g., I-IV):'
  },
  orientation: {
    id: 'app.containers.ModelFieldMessages.orientation',
    defaultMessage: 'Orientation'
  },
  downRotation: {
    id: 'app.containers.ModelFieldMessages.downRotation',
    defaultMessage: 'Down'
  },
  upRotation: {
    id: 'app.containers.ModelFieldMessages.upRotation',
    defaultMessage: 'Up'
  },
  altRotation: {
    id: 'app.containers.ModelFieldMessages.altRotation',
    defaultMessage: 'Alt'
  },
  rotation: {
    id: 'app.containers.ModelFieldMessages.rotation',
    defaultMessage: 'Rotation'
  },
  direction: {
    id: 'app.containers.ModelFieldMessages.direction',
    defaultMessage: 'Direction'
  },
  kallesoe: {
    id: 'app.containers.ModelFieldMessages.kallesoe',
    defaultMessage: 'Kallesoe'
  },
  exit: {
    id: 'app.containers.ModelFieldMessages.exit',
    defaultMessage: 'Exit'
  },
  lamellaDimensions: {
    id: 'app.containers.ModelFieldMessages.lamellaDimensions',
    defaultMessage: 'Dimensions'
  },
  addedAt: {
    id: 'app.containers.ModelFieldMessages.addedAt',
    defaultMessage: 'Added at'
  },
  productionStartedAt: {
    id: 'app.containers.ModelFieldMessages.productionStartedAt',
    defaultMessage: 'Production Start'
  },
  completedAt: {
    id: 'app.containers.ModelFieldMessages.completedAt',
    defaultMessage: 'Completed at'
  },
  orderType: {
    id: 'app.containers.ModelFieldMessages.orderType',
    defaultMessage: 'Type'
  },
  lineRegime: {
    id: 'app.containers.ModelFieldMessages.lineRegime',
    defaultMessage: 'Line Regime'
  },
  clientTitle: {
    id: 'app.containers.ModelFieldMessages.clientTitle',
    defaultMessage: 'Client'
  },
  client: {
    id: 'app.containers.ModelFieldMessages.client',
    defaultMessage: 'Client'
  },
  clientId: {
    id: 'app.containers.ModelFieldMessages.clientId',
    defaultMessage: 'Client Id'
  },
  piecesTitle: {
    id: 'app.containers.ModelFieldMessages.piecesTitle',
    defaultMessage: 'Pieces'
  },
  deleteTitle: {
    id: 'app.containers.ModelFieldMessages.deleteTitle',
    defaultMessage: 'Delete'
  },
  duplicateTitle: {
    id: 'app.containers.ModelFieldMessages.duplicateTitle',
    defaultMessage: 'Duplicate'
  },
  deleteText: {
    id: 'app.containers.ModelFieldMessages.deleteText',
    defaultMessage: 'Are you sure you want to move order back to to-do?'
  },
  duplicateText: {
    id: 'app.containers.ModelFieldMessages.duplicateText',
    defaultMessage: 'Are you sure you want to duplicate lamella?'
  },
  finishedProducingInsZinc: {
    id: 'app.containers.ModelFieldMessages.finishedProducingInsZinc',
    defaultMessage: 'Through ISO zinc'
  },
  producedOnZinc: {
    id: 'app.containers.ModelFieldMessages.producedOnZinc',
    defaultMessage: 'Through zinc'
  },
  startSequence: {
    id: 'app.containers.ModelFieldMessages.startSequence',
    defaultMessage: 'Start Seq'
  },
  endSequence: {
    id: 'app.containers.ModelFieldMessages.endSequence',
    defaultMessage: 'End Seq'
  },
  producingOnZinc: {
    id: 'app.containers.ModelFieldMessages.producingOnZinc',
    defaultMessage: 'On zinc'
  },
  producingOnISoZinc: {
    id: 'app.containers.ModelFieldMessages.producingOnISoZinc',
    defaultMessage: 'On ISO zinc '
  },
  finishedProducingOnZPress: {
    id: 'app.containers.ModelFieldMessages.finishedProducingOnZPress',
    defaultMessage: 'Through Zpress'
  },
  producingOnZPress: {
    id: 'app.containers.ModelFieldMessages.producingOnZPress',
    defaultMessage: 'On Zpress'
  },
  revertOrder: {
    id: 'app.containers.ModelFieldMessages.revertOrder',
    defaultMessage: 'Revert'
  },
  removeRepairOrder: {
    id: 'app.containers.ModelFieldMessages.removeRepairOrder',
    defaultMessage: 'Rm. rpr.'
  },
  repairOrder: {
    id: 'app.containers.ModelFieldMessages.repairOrder',
    defaultMessage: 'Repair order'
  },
  fullyAssignedOnDrying: {
    id: 'app.containers.ModelFieldMessages.fullyAssignedOnDrying',
    defaultMessage: 'All lam. drying'
  },
  lamellaId: {
    id: 'app.containers.ModelFieldMessages.lamellaId',
    defaultMessage: 'ID'
  },
  rawThickness: {
    id: 'app.containers.ModelFieldMessages.rawThickness',
    defaultMessage: 'Raw thickness (mm)'
  },
  rawThicknessSchneider: {
    id: 'app.containers.ModelFieldMessages.rawThicknessSchneider',
    defaultMessage: 'Raw thickness (mm)'
  },
  imperialRawThickness: {
    id: 'app.containers.ModelFieldMessages.imperialRawThickness',
    defaultMessage: 'Raw thickness'
  },
  planningNumber: {
    id: 'app.containers.ModelFieldMessages.planningNumber',
    defaultMessage: 'Planning Number'
  },
  boardsPerLayer: {
    id: 'app.containers.ModelFieldMessages.boardsPerLayer',
    defaultMessage: 'Boards Per Layer'
  },
  layerNumber: {
    id: 'app.containers.ModelFieldMessages.layerNumber',
    defaultMessage: 'Number of Layers'
  },
  sticks: {
    id: 'app.containers.ModelFieldMessages.sticks',
    defaultMessage: 'Sticks'
  },
  mostProjectId: {
    id: 'app.containers.ModelFieldMessages.mostProjectId',
    defaultMessage: 'Most Project Id'
  },
  infeedQuality: {
    id: 'app.containers.ModelFieldMessages.infeedQuality',
    defaultMessage: 'Infeed Quality'
  },
  brookhuisSetting: {
    id: 'app.containers.ModelFieldMessages.brookhuisSetting',
    defaultMessage: 'Brookhuis Setting'
  },
  smartySetting: {
    id: 'app.containers.ModelFieldMessages.smartySetting',
    defaultMessage: 'Smarty Setting'
  },
  quantity: {
    id: 'app.containers.ModelFieldMessages.quantity',
    defaultMessage: 'Quantity'
  },
  task_id: {
    id: 'app.containers.ModelFieldMessages.task_id',
    defaultMessage: 'Task ID'
  },
  task_name: {
    id: 'app.containers.ModelFieldMessages.task_name',
    defaultMessage: 'Task Name'
  },
  taskStatus: {
    id: 'app.containers.ModelFieldMessages.taskStatus',
    defaultMessage: 'Task Status'
  },
  W3Takeaway: {
    id: 'app.containers.ModelFieldMessages.W3Takeaway',
    defaultMessage: 'W3Takeaway'
  },
  W1Takeaway: {
    id: 'app.containers.ModelFieldMessages.W1Takeaway',
    defaultMessage: 'W1Takeaway'
  },
  imperialW1Takeaway: {
    id: 'app.containers.ModelFieldMessages.imperialW1Takeaway',
    defaultMessage: 'Left side takeaway'
  },
  imperialW3Takeaway: {
    id: 'app.containers.ModelFieldMessages.imperialW3Takeaway',
    defaultMessage: 'Right side takeaway'
  },
  planerSetup: {
    id: 'app.conatiners.ModelFieldMessages.PlanerSetup',
    defaultMessage: 'Planer Setup'
  },
  rawWidth: {
    id: 'app.containers.ModelFieldMessages.rawWidth',
    defaultMessage: 'Raw width (mm)'
  },
  imperialRawWidth: {
    id: 'app.containers.ModelFieldMessages.imperialRawWidth',
    defaultMessage: 'Raw width'
  },
  rauteDataOutput: {
    id: 'app.containers.Products.rauteDataOutput',
    defaultMessage: 'Raute Data Output'
  },
  packageId: {
    id: 'app.containers.ModelFieldMessages.packageId',
    defaultMessage: 'Package ID'
  },
  quality: {
    id: 'app.containers.ModelFieldMessages.quality',
    defaultMessage: 'Quality'
  },
  abfQuality: {
    id: 'app.containers.ModelFieldMessages.abfQuality',
    defaultMessage: 'Quality (Abf)'
  },
  press: {
    id: 'app.containers.ModelFieldMessages.press',
    defaultMessage: 'Press'
  },
  orderQuality: {
    id: 'app.containers.ModelFieldMessages.orderQuality',
    defaultMessage: 'Quality'
  },
  packageQuality: {
    id: 'app.containers.ModelFieldMessages.packageQuality',
    defaultMessage: 'Package qual.'
  },
  dateCreated: {
    id: 'app.containers.ModelFieldMessages.dateCreated',
    defaultMessage: 'Created'
  },
  deleteLamellaText: {
    id: 'app.containers.ModelFieldMessages.deleteLamellaText',
    defaultMessage: 'Are you sure you want to delete lamella?'
  },
  numOfLayers: {
    id: 'app.containers.ModelFieldMessages.numOfLayers',
    defaultMessage: 'No. layers'
  },
  sandingTop: {
    id: 'app.containers.ModelFieldMessages.sandingTop',
    defaultMessage: 'Sanding top'
  },
  thickness: {
    id: 'app.containers.ModelFieldMessages.thickness',
    defaultMessage: 'Thickness (mm)'
  },
  thicknessRaw: {
    id: 'app.containers.ModelFieldMessages.thicknessRaw',
    defaultMessage: 'Thickness Raw (mm)'
  },
  width: {
    id: 'app.containers.ModelFieldMessages.width',
    defaultMessage: 'Width (mm)'
  },
  sandingBottom: {
    id: 'app.containers.ModelFieldMessages.sandingBottom',
    defaultMessage: 'Sanding bottom'
  },
  oversizeThickness: {
    id: 'app.containers.ModelFieldMessages.oversizeThickness',
    defaultMessage: 'Oversize thickness (mm)'
  },
  oversizeLength: {
    id: 'app.containers.ModelFieldMessages.oversizeLength',
    defaultMessage: 'Pre-press oversize length (mm)'
  },
  identifierUsedAt: {
    id: 'app.containers.ModelFieldMessages.identifierUsedAt',
    defaultMessage: 'Used at'
  },
  identifierNumRows: {
    id: 'app.containers.ModelFieldMessages.identifierNumRows',
    defaultMessage: 'No. rows'
  },
  identifierNumColumns: {
    id: 'app.containers.ModelFieldMessages.identifierNumColumns',
    defaultMessage: 'No. cols'
  },
  identifierNumBoards: {
    id: 'app.containers.ModelFieldMessages.identifierNumBoards',
    defaultMessage: 'No. boards'
  },
  identifierCreatedAt: {
    id: 'app.containers.ModelFieldMessages.identifierCreatedAt',
    defaultMessage: 'Created at'
  },
  identifierProductionDate: {
    id: 'app.containers.ModelFieldMessages.identifierProductionDate',
    defaultMessage: 'Produced at'
  },
  identifierSuppliedDate: {
    id: 'app.containers.ModelFieldMessages.identifierSuppliedDate',
    defaultMessage: 'Supplied at'
  },
  username: {
    id: 'app.containers.ModelFieldMessages.username',
    defaultMessage: 'Username'
  },
  name: {
    id: 'app.containers.ModelFieldMessages.name',
    defaultMessage: 'Name'
  },
  surname: {
    id: 'app.containers.ModelFieldMessages.surname',
    defaultMessage: 'Surname'
  },
  woodType: {
    id: 'app.containers.ModelFieldMessages.woodType',
    defaultMessage: 'woodType'
  },
  bodenlagerMoveText: {
    id: 'app.containers.ModelFieldMessages.bodenlagerMoveText',
    defaultMessage: 'Bist du sicher, dass du der Paket auf den alte linie ziehen willst?'
  },
  bodenlagerMove: {
    id: 'app.containers.ModelFieldMessages.bodenlagerMove',
    defaultMessage: 'Auf den alte linie ziehen'
  },
  incomingLamellaDimensions: {
    id: 'app.containers.ModelFieldMessages.incomingLamellaDimensions',
    defaultMessage: 'Incoming'
  },
  boenlagerDeleteText: {
    id: 'app.containers.ModelFieldMessages.boenlagerDeleteText',
    defaultMessage: 'Are you sure you want to delete bodenlager package?'
  },
  package_m3: {
    id: 'app.containers.ModelFieldMessages.packagem3',
    defaultMessage: 'Cubic meters'
  },
  roleTitle: {
    id: 'app.containers.Users.roleTitle',
    defaultMessage: 'Role'
  },
  adminRole: {
    id: 'app.containers.Users.adminRole',
    defaultMessage: 'Admin'
  },
  workerRole: {
    id: 'app.containers.Users.workerRole',
    defaultMessage: 'Worker'
  },
  passwordField: {
    id: 'app.containers.Users.passwordField',
    defaultMessage: 'Password'
  },
  layers: {
    id: 'app.containers.Users.layers',
    defaultMessage: 'Layers'
  },
  combinePressId: {
    id: 'app.containers.Users.combinePressId',
    defaultMessage: 'Combined Id'
  },
  layerNo: {
    id: 'app.containers.Users.layerNo',
    defaultMessage: 'Layer number'
  },
  topTension: {
    id: 'app.containers.Users.topTension',
    defaultMessage: 'Top Tension'
  },
  topN1D: {
    id: 'app.containers.Users.topN1D',
    defaultMessage: 'Top N1D'
  },
  coreN2M: {
    id: 'app.containers.Users.coreN2M',
    defaultMessage: 'Core N2M'
  },
  bottomN1D: {
    id: 'app.containers.Users.bottomN1D',
    defaultMessage: 'Bottom N1D'
  },
  bottomTension: {
    id: 'app.containers.Users.bottomTension',
    defaultMessage: 'Bottom Tension'
  },
  iBeamNumber: {
    id: 'app.containers.Users.iBeamNumber',
    defaultMessage: 'Num. of Beams'
  },
  panelDimensions: {
    id: 'app.containers.Users.panelDimensions',
    defaultMessage: 'Panel size'
  },
  smallPanelDimensions: {
    id: 'app.containers.Users.smallPanelDimensions',
    defaultMessage: 'Small panel size'
  },
  kvhDimensions: {
    id: 'app.containers.Users.kvhDimensions',
    defaultMessage: 'Dimensions'
  },
  bshDimensions: {
    id: 'app.containers.Users.bshDimensions',
    defaultMessage: 'Dimensions'
  },
  dimensions: {
    id: 'app.containers.Users.dimensions',
    defaultMessage: 'Dimensions'
  },
  schneiderDimensions: {
    id: 'app.containers.Users.schneiderDimensions',
    defaultMessage: 'Dimensions'
  },
  rawDimensions: {
    id: 'app.containers.Users.rawDimensions',
    defaultMessage: 'Raw dimensions'
  },
  insDimensions: {
    id: 'app.containers.Users.insDimensions',
    defaultMessage: 'Ins Dimensions'
  },
  averageLength: {
    id: 'app.containers.Users.averageLength',
    defaultMessage: 'Avg. length (mm)'
  },
  planedDimensions: {
    id: 'app.containers.Users.planedDimensions',
    defaultMessage: 'Pl. dim.'
  },
  planedDimensionsSchneider: {
    id: 'app.containers.Users.planedDimensionsSchneider',
    defaultMessage: 'Pl. dim.'
  },
  panelThickness: {
    id: 'app.containers.Users.panelThickness',
    defaultMessage: 'Billet thickness (mm)'
  },
  panelThicknessSchneider: {
    id: 'app.containers.Users.panelThicknessSchneider',
    defaultMessage: 'Billet thickness (mm)'
  },
  panelWidth: {
    id: 'app.containers.Users.panelWidth',
    defaultMessage: 'Billet width (mm)'
  },
  beamWidth: {
    id: 'app.containers.Users.beamWidth',
    defaultMessage: 'Beam width (mm)'
  },
  numberOfBeams: {
    id: 'app.containers.Users.numberOfBeams',
    defaultMessage: 'No. Beams'
  },
  beamPartLengths: {
    id: 'app.containers.Users.beamPartLengths',
    defaultMessage: 'beamPartLengths'
  },
  panelLength: {
    id: 'app.containers.Users.panelLength',
    defaultMessage: 'Billet length (mm)'
  },
  panelsNumber: {
    id: 'app.containers.Users.panelsNumber',
    defaultMessage: 'Number of panels'
  },
  productNumber: {
    id: 'app.containers.Users.productNumber',
    defaultMessage: 'Number of products'
  },
  panelsDone: {
    id: 'app.containers.Users.panelsDone',
    defaultMessage: 'Panels done'
  },
  etage: {
    id: 'app.containers.Users.inEtage',
    defaultMessage: 'Etage'
  },
  idTitle: {
    id: 'app.containers.EventLog.idTitle',
    defaultMessage: 'ID'
  },
  group: {
    id: 'app.containers.EventLog.group',
    defaultMessage: 'Group ID'
  },
  userTitle: {
    id: 'app.containers.EventLog.userTitle',
    defaultMessage: 'User'
  },
  packageTitle: {
    id: 'app.components.XcutBox.package',
    defaultMessage: 'Package'
  },
  typeTitle: {
    id: 'app.containers.ModelFieldMessages.orderType',
    defaultMessage: 'Type'
  },
  addedTitle: {
    id: 'app.containers.EventLog.addedTitle',
    defaultMessage: 'Time ocurred'
  },
  sign_in: {
    id: 'app.containers.EventLog.sign_in',
    defaultMessage: 'User signed in'
  },
  sideCut: {
    id: 'app.containers.Products.sideCut',
    defaultMessage: 'Side Cut'
  },
  order_added: {
    id: 'app.containers.EventLog.order_added',
    defaultMessage: 'Order added'
  },
  order_added_to_todo: {
    id: 'app.containers.EventLog.order_added_to_todo',
    defaultMessage: 'Order added to to-do'
  },
  order_removed_from_todo: {
    id: 'app.containers.EventLog.order_removed_from_todo',
    defaultMessage: 'Order removed from to-do'
  },
  order_added_to_production: {
    id: 'app.containers.EventLog.order_added_to_production',
    defaultMessage: 'Order added to production'
  },
  incoming_package_added_to_production: {
    id: 'app.containers.EventLog.incoming_package_added_to_production',
    defaultMessage: 'Incoming package added to production'
  },
  incoming_package_unloaded: {
    id: 'app.containers.EventLog.incoming_package_unloaded',
    defaultMessage: 'Incoming package unloaded'
  },
  order_removed_from_production: {
    id: 'app.containers.EventLog.order_removed_from_production',
    defaultMessage: 'Order removed from production'
  },
  error: {
    id: 'app.containers.EventLog.error',
    defaultMessage: 'Error ocurred'
  },
  user_added: {
    id: 'app.containers.EventLog.user_added',
    defaultMessage: 'User added'
  },
  order_deleted: {
    id: 'app.containers.EventLog.order_deleted',
    defaultMessage: 'Order deleted'
  },
  incoming_package_added: {
    id: 'app.containers.EventLog.incoming_package_added',
    defaultMessage: 'Incoming package added'
  },
  incoming_package_used: {
    id: 'app.containers.EventLog.incoming_package_used',
    defaultMessage: 'Incoming package used'
  },
  outgoing_package_added: {
    id: 'app.containers.EventLog.outgoing_package_added',
    defaultMessage: 'Package created'
  },
  additional_data: {
    id: 'app.containers.EventLog.additionalDataTitle',
    defaultMessage: 'Additional data'
  },
  querschieber_fehler: {
    id: 'app.containers.EventLog.querschieber_fehler',
    defaultMessage: 'Querschieber length error'
  },
  button_pressed: {
    id: 'app.containers.EventLog.button_pressed',
    defaultMessage: 'Button pressed'
  },
  fehler: {
    id: 'app.containers.EventLog.fehler',
    defaultMessage: 'Error'
  },
  reserve: {
    id: 'app.containers.EventLog.reserve',
    defaultMessage: 'Extra'
  },
  info: {
    id: 'app.containers.EventLog.info',
    defaultMessage: 'Info'
  },
  area: {
    id: 'app.containers.EventLog.area',
    defaultMessage: 'area'
  },
  orderId: {
    id: 'app.containers.EventLog.orderId',
    defaultMessage: 'Order ID'
  },
  loader_info: {
    id: 'app.containers.EventLog.loader_info',
    defaultMessage: 'Info - Infeed'
  },
  zinc_info: {
    id: 'app.containers.EventLog.zinc_info',
    defaultMessage: 'Info - Kontizink'
  },
  drying_info: {
    id: 'app.containers.EventLog.drying_info',
    defaultMessage: 'Info - Curing deck'
  },
  longlamellastorage_info: {
    id: 'app.containers.EventLog.longlamellastorage_info',
    defaultMessage: 'Info - Long lamella storage'
  },
  longMaterialStacker: {
    id: 'app.containers.Products.longMaterialStacker',
    defaultMessage: 'LMaterialStacker'
  },
  shortlamellastorage_info: {
    id: 'app.containers.EventLog.shortlamellastorage_info',
    defaultMessage: 'Info - Short lamella storage'
  },
  press_info: {
    id: 'app.containers.EventLog.press_info',
    defaultMessage: 'Info - Press'
  },
  cutListSent: {
    id: 'app.containers.IncomingPackage.cutListSent',
    defaultMessage: 'cutListSent'
  },
  productStacked: {
    id: 'app.containers.IncomingPackage.productStacked',
    defaultMessage: 'Stacking finished'
  },
  sentToDB: {
    id: 'app.containers.IncomingPackage.sentToDB',
    defaultMessage: 'sentToDB'
  },
  sendToLoaded: {
    id: 'app.containers.IncomingPackage.sendToLoaded',
    defaultMessage: 'To XLAM'
  },
  sync: {
    id: 'app.containers.IncomingPackage.sync',
    defaultMessage: 'Sync'
  },
  passedBanner: {
    id: 'app.containers.IncomingPackage.passedBanner',
    defaultMessage: 'Banner passed'
  },
  cutList: {
    id: 'app.containers.IncomingPackage.cutList',
    defaultMessage: 'cuts'
  },
  goodWood: {
    id: 'app.containers.IncomingPackage.goodWood',
    defaultMessage: 'goodWood'
  },
  badWood: {
    id: 'app.containers.IncomingPackage.badWood',
    defaultMessage: 'badWood'
  },
  woodTreatment: {
    id: 'app.containers.ModelFieldMessages.woodTreatment',
    defaultMessage: 'Wood Treatment'
  },
  rawLamellaIdentifier: {
    id: 'app.containers.IncomingPackage.rawLamellaIdentifier',
    defaultMessage: 'Raw Lamella Identifier'
  },
  order_id: {
    id: 'app.containers.Compartments.order_id',
    defaultMessage: 'Order ID'
  },
  order_identifier: {
    id: 'app.containers.Compartments.order_identifier',
    defaultMessage: 'Order Identifier'
  },
  layer_number: {
    id: 'app.containers.Compartments.layer_number',
    defaultMessage: 'Layer Number'
  },
  actual_number_of_lamellas: {
    id: 'app.containers.Compartments.actual_number_of_lamellas',
    defaultMessage: 'Actual Number Of Lamellas'
  },
  set_number_of_lamellas: {
    id: 'app.containers.Compartments.set_number_of_lamellas',
    defaultMessage: 'Set Number Of Lamellas'
  },
  data: {
    id: 'app.containers.XCutEvents.data',
    defaultMessage: 'Data'
  },
  minQuantity: {
    id: 'app.containers.XCutEvents.minQuantity',
    defaultMessage: 'Min'
  },
  maxQuantity: {
    id: 'app.containers.XCutEvents.maxQuantity',
    defaultMessage: 'Max'
  },
  materialOutput: {
    id: 'app.containers.Products.materialOutput',
    defaultMessage: 'Material Output'
  },
  currentQuantity: {
    id: 'app.containers.XCutEvents.currentQuantity',
    defaultMessage: 'Current'
  },
  isKVH: {
    id: 'app.containers.XCutEvents.isKVH',
    defaultMessage: 'KVH'
  },
  turnPanel: {
    id: 'app.containers.XCutEvents.turnPanel',
    defaultMessage: 'turnPanel'
  },
  splitLamella: {
    id: 'app.containers.XCutEvents.splitLamella',
    defaultMessage: 'Split Lamella'
  },
  wrapping: {
    id: 'app.containers.XCutEvents.wrapping',
    defaultMessage: 'Wrapping'
  },
  headCut: {
    id: 'app.containers.XCutEvents.headCut',
    defaultMessage: 'Head Cut'
  },
  middleCut: {
    id: 'app.containers.XCutEvents.middleCut',
    defaultMessage: 'Middle Cut'
  },
  endCut: {
    id: 'app.containers.XCutEvents.endCut',
    defaultMessage: 'End Cut'
  },
  withMosca: {
    id: 'app.containers.XCutEvents.withMosca',
    defaultMessage: 'With Mosca'
  },
  stopper: {
    id: 'app.containers.XCutEvents.stopper',
    defaultMessage: 'Anschlag (2400 - 12650 mm)'
  },
  primingTop: {
    id: 'app.containers.ModelFieldMessages.primingTop',
    defaultMessage: 'Priming top'
  },
  primingBottom: {
    id: 'app.containers.ModelFieldMessages.primingBottom',
    defaultMessage: 'Priming bottom'
  },
  chamferTop: {
    id: 'app.containers.ModelFieldMessages.chamferTop',
    defaultMessage: 'Chamfer top'
  },
  chamferBottom: {
    id: 'app.containers.ModelFieldMessages.chamferBottom',
    defaultMessage: 'Chamfer bottom'
  },
  numKVHLayers: {
    id: 'app.containers.ModelFieldMessages.numKVHLayers',
    defaultMessage: 'No. of KVH Layers'
  },
  isBeam: {
    id: 'app.containers.ModelFieldMessages.isBeam',
    defaultMessage: 'Is Beam'
  },
  largeFingerJoint: {
    id: 'app.containers.ModelFieldMessages.largeFingerJoint',
    defaultMessage: 'Large Finger Joint'
  },
  fixedRecipeLayer: {
    id: 'app.containers.ModelFieldMessages.fixedRecipeLayer',
    defaultMessage: 'fixed Recipe'
  },
  chamfer: {
    id: 'app.containers.ModelFieldMessages.chamfer',
    defaultMessage: 'Chamfer'
  },
  split: {
    id: 'app.containers.ModelFieldMessages.split',
    defaultMessage: 'split'
  },
  numLamellasPerLayer: {
    id: 'app.containers.ModelFieldMessages.numLamellasPerLayer',
    defaultMessage: 'No. lamellas per layer'
  },
  batchId: {
    id: 'app.containers.ModelFieldMessages.batchId',
    defaultMessage: 'Batch Id'
  },
  beamOversize: {
    id: 'app.containers.ModelFieldMessages.beamOversize',
    defaultMessage: 'Beam Oversize'
  },
  separatorDistance: {
    id: 'app.containers.ModelFieldMessages.separatorDistance',
    defaultMessage: 'Sep. distance'
  },
  jobCode: {
    id: 'app.containers.ModelFieldMessages.jobCode',
    defaultMessage: 'Job code'
  },
  minTakeaway: {
    id: 'app.containers.ModelFieldMessages.minTakeaway',
    defaultMessage: 'Min takeaway (0.1mm)'
  },
  maxTakeaway: {
    id: 'app.containers.ModelFieldMessages.maxTakeaway',
    defaultMessage: 'Max takeaway (0.1mm)'
  },
  rowsNumber: {
    id: 'app.containers.ModelFieldMessages.rowsNumber',
    defaultMessage: 'Rows'
  },
  colsNumber: {
    id: 'app.containers.ModelFieldMessages.colsNumber',
    defaultMessage: 'Columns'
  },
  grade: {
    id: 'app.containers.modelFieldMessages.strengthGrade',
    defaultMessage: 'Strength grade'
  },
  strengthComb: {
    id: 'app.containers.modelFieldMessages.strengthComb',
    defaultMessage: 'Strength Comb.'
  },
  kvhBoards: {
    id: 'app.containers.modelFieldMessages.kvhBoards',
    defaultMessage: 'No. KVH'
  },
  groupString: {
    id: 'app.containers.modelFieldMessages.groupString',
    defaultMessage: 'Gr. str.'
  },
  lamellasPerPress: {
    id: 'app.containers.modelFieldMessages.lamellasPerPress',
    defaultMessage: 'Lamellas Per Press.'
  },
  noGroupLamellasNeeded: {
    id: 'app.containers.modelFieldMessages.noGroupLamellasNeeded',
    defaultMessage: 'NGM Lamellas Needed.'
  },
  numberOfLamellas: {
    id: 'app.containers.modelFieldMessages.numberOfLamellas',
    defaultMessage: 'Lamellas'
  },
  numberOfLayers: {
    id: 'app.containers.modelFieldMessages.numberOfLayers',
    defaultMessage: 'Number Of Layers'
  },
  actualPanels: {
    id: 'app.containers.modelFieldMessages.actualPanels',
    defaultMessage: 'No. of panels'
  },
  numberOfLamellasStacked: {
    id: 'app.containers.modelFieldMessages.numberOfLamellasStacked',
    defaultMessage: 'Num. stacked'
  },
  numberOfLamellasPlaned: {
    id: 'app.containers.modelFieldMessages.numberOfLamellasPlaned',
    defaultMessage: 'Num. planed'
  },
  sortingState: {
    id: 'app.containers.modelFieldMessages.sortingState',
    defaultMessage: 'Sorting state'
  },
  assignedToEtage: {
    id: 'app.containers.modelFieldMessages.assignedToEtage',
    defaultMessage: 'Assigned to etage'
  },
  orderColor: {
    id: 'app.containers.ModelFieldMessages.orderColor',
    defaultMessage: 'Order color'
  },
  led_pckg_id: {
    id: 'app.containers.ModelFieldMessages.ledinek_package_id',
    defaultMessage: 'Ledinek ID'
  },
  plusOne: {
    id: 'app.containers.ModelFieldMessages.plusOne',
    defaultMessage: 'plus One'
  },
  minusOne: {
    id: 'app.containers.ModelFieldMessages.minusOne',
    defaultMessage: 'minus One'
  },
  articleNumber: {
    id: 'app.containers.ModelFieldMessages.articleNumber',
    defaultMessage: 'Article nr'
  },
  inputArticleNumber: {
    id: 'app.containers.ModelFieldMessages.inputArticleNumber',
    defaultMessage: 'In. article'
  },
  outputArticleNumber: {
    id: 'app.containers.ModelFieldMessages.outputArticleNumber',
    defaultMessage: 'Out. article'
  },
  print: {
    id: 'app.containers.ModelFieldMessages.print',
    defaultMessage: 'Print'
  },
  syncDatabaseControl: {
    id: 'app.containers.ModelFieldMessages.syncDatabaseControl',
    defaultMessage: 'Sync DB Control'
  },
  printer: {
    id: 'app.containers.ModelFieldMessages.printer',
    defaultMessage: 'Printer'
  },
  printed: {
    id: 'app.containers.ModelFieldMessages.printed',
    defaultMessage: 'Printed'
  },
  pieces: {
    id: 'app.containers.ModelFieldMessages.pieces',
    defaultMessage: 'Pieces'
  },
  lmNetto: {
    id: 'app.containers.ModelFieldMessages.lmNetto',
    defaultMessage: 'Lm netto'
  },
  lmBrutto: {
    id: 'app.containers.ModelFieldMessages.lmBrutto',
    defaultMessage: 'Lm brutto'
  },
  planingOffset: {
    id: 'app.containers.ModelFieldMessages.planingOffset',
    defaultMessage: 'Pl. offset'
  },
  saQuality: {
    id: 'app.containers.ModelFieldMessages.saQuality',
    defaultMessage: 'SA'
  },
  laQuality: {
    id: 'app.containers.ModelFieldMessages.laQuality',
    defaultMessage: 'LA'
  },
  lcQuality: {
    id: 'app.containers.ModelFieldMessages.lcQuality',
    defaultMessage: 'LC'
  },
  mlQuality: {
    id: 'app.containers.ModelFieldMessages.mlQuality',
    defaultMessage: 'ML'
  },
  dlQuality: {
    id: 'app.containers.ModelFieldMessages.dlQuality',
    defaultMessage: 'DL'
  },
  confirm: {
    id: 'app.containers.ModelFieldMessages.confirm',
    defaultMessage: 'Confirm'
  },
  confirmMaterial: {
    id: 'app.containers.ModelFieldMessages.confirmMaterial',
    defaultMessage: 'Confirm Material'
  },
  calculatedLamellas: {
    id: 'app.containers.ModelFieldMessages.calculatedLamellas',
    defaultMessage: 'Need to Press'
  },
  zPressDonePlates: {
    id: 'app.containers.ModelFieldMessages.zPressDonePlates',
    defaultMessage: 'Layers Made'
  },
  zPressDoneNumLamellas: {
    id: 'app.containers.ModelFieldMessages.zPressDoneNumLamellas',
    defaultMessage: 'Pressed lamellas'
  },
  numberOfPlates: {
    id: 'app.containers.ModelFieldMessages.numberOfPlates',
    defaultMessage: 'No. plates'
  },
  outerLayer: {
    id: 'app.containers.ModelFieldMessages.outerLayer',
    defaultMessage: 'Outer Layer'
  },
  picture: {
    id: 'app.containers.ModelFieldMessages.picture',
    defaultMessage: 'Model picture'
  },
  layer: {
    id: 'app.containers.ModelFieldMessages.layer',
    defaultMessage: 'Layer'
  },
  hasGlue: {
    id: 'app.containers.ModelFieldMessages.hasGlue',
    defaultMessage: 'Has glue'
  },
  isOuter: {
    id: 'app.containers.ModelFieldMessages.isOuter',
    defaultMessage: 'Outer Layer'
  },
  whiteGlue: {
    id: 'app.containers.ModelFieldMessages.whiteGlue',
    defaultMessage: 'White glue'
  },
  linesOfWhiteGlue: {
    id: 'app.containers.ModelFieldMessages.linesOfWhiteGlue',
    defaultMessage: 'Lines of Whiteglue'
  },
  linesOfWhiteGlueUnipress: {
    id: 'app.containers.ModelFieldMessages.linesOfWhiteGlueUnipress',
    defaultMessage: 'Lines of Whiteglue UNI'
  },
  linesOfHotmelt: {
    id: 'app.containers.ModelFieldMessages.linesOfHotmelt',
    defaultMessage: 'Lines of Hotmelt'
  },
  posWhiteGlue: {
    id: 'app.containers.ModelFieldMessages.posWhiteGlue',
    defaultMessage: 'Pos Whiteglue'
  },
  posHotmelt: {
    id: 'app.containers.ModelFieldMessages.posHotmelt',
    defaultMessage: 'Pos Hotmelt'
  },
  minTakeawayMM: {
    id: 'app.containers.ModelFieldMessages.minTakeawayMM',
    defaultMessage: 'Min width takeaway (mm)'
  },
  projectNumber: {
    id: 'app.containers.ModelFieldMessages.projectNumber',
    defaultMessage: 'Project Number'
  },
  finishedPackageId: {
    id: 'app.containers.ModelFieldMessages.finishedPackageId',
    defaultMessage: 'Finished Package Id'
  },
  select: {
    id: 'app.containers.ModelFieldMessages.select',
    defaultMessage: 'Select'
  },
  project: {
    id: 'app.containers.ModelFieldMessages.project',
    defaultMessage: 'Project'
  },
  projectActive: {
    id: 'app.containers.ModelFieldMessages.projectActive',
    defaultMessage: 'ProjectActive'
  },
  recipe: {
    id: 'app.containers.ModelFieldMessages.recipe',
    defaultMessage: 'Recipe'
  },
  recipeLayer: {
    id: 'app.containers.ModelFieldMessages.recipeLayer',
    defaultMessage: 'Layer Recipe'
  },
  recipeSelect: {
    id: 'app.containers.ModelFieldMessages.recipeSelect',
    defaultMessage: 'Select Recipe'
  },
  projectSelect: {
    id: 'app.containers.ModelFieldMessages.projectSelect',
    defaultMessage: 'Project Number'
  },
  recipeLayerSelect: {
    id: 'app.containers.ModelFieldMessages.recipeLayerSelect',
    defaultMessage: 'Select Layer Recipe'
  },
  platesOnLine: {
    id: 'app.containers.ModelFieldMessages.platesOnLine',
    defaultMessage: 'Plates on line'
  },
  platesInStorage: {
    id: 'app.containers.ModelFieldMessages.platesInStorage',
    defaultMessage: 'Plates in storage'
  },
  totalPlates: {
    id: 'app.containers.ModelFieldMessages.totalPlates',
    defaultMessage: 'Total plates'
  },
  editJD: {
    id: 'app.containers.ModelFieldMessages.editJD',
    defaultMessage: 'edit JD'
  },
  edit: {
    id: 'app.containers.ModelFieldMessages.edit',
    defaultMessage: 'Edit'
  },
  delete: {
    id: 'app.containers.ModelFieldMessages.delete',
    defaultMessage: 'Delete'
  },
  editLayer: {
    id: 'app.containers.ModelFieldMessages.editLayer',
    defaultMessage: 'edit Layer'
  },
  orderWithRecipe: {
    id: 'app.containers.ModelFieldMessages.addOrderWithRecipe',
    defaultMessage: 'Order with recipe'
  },
  fileName: {
    id: 'app.containers.ModelFieldMessages.fileName',
    defaultMessage: 'File name'
  },
  timestamp: {
    id: 'app.containers.ModelFieldMessages.timestamp',
    defaultMessage: 'Timestamp'
  },
  masterpanel: {
    id: 'app.containers.ModelFieldMessages.masterpanel',
    defaultMessage: 'Masterpanel'
  },
  masterErpId: {
    id: 'app.containers.ModelFieldMessages.masterErpId',
    defaultMessage: 'Master Erp ID'
  },
  masterId: {
    id: 'app.containers.ModelFieldMessages.masterId',
    defaultMessage: 'Master ID'
  },
  combinedPressId: {
    id: 'app.containers.ModelFieldMessages.combinedPressId',
    defaultMessage: 'Combined ID'
  },
  lamellasInOnePackage: {
    id: 'app.containers.ModelFieldMessages.lamellasInOnePackage',
    defaultMessage: 'Lam. pckg.'
  },
  filters: {
    id: 'app.containers.ModelFieldMessages.filters',
    defaultMessage: 'Filters'
  },
  inPackageAlready: {
    id: 'app.containers.ModelFieldMessages.inPackageAlready',
    defaultMessage: 'inPackageAlready'
  },
  projectName: {
    id: 'app.containers.ModelFieldMessages.projectName',
    defaultMessage: 'Project name'
  },
  orderNumber: {
    id: 'app.containers.ModelFieldMessages.orderNumber',
    defaultMessage: 'Order Number'
  },
  nestingPanelName: {
    id: 'app.containers.ModelFieldMessages.nestingPanelName',
    defaultMessage: 'Nesting panel name'
  },
  nestingPanelNumber: {
    id: 'app.containers.ModelFieldMessages.nestingPanelNumber',
    defaultMessage: 'Nesting panel number'
  },
  nestingPanelNumberDone: {
    id: 'app.containers.ModelFieldMessages.nestingPanelNumberDone',
    defaultMessage: 'Nesting panel number done'
  },
  singleLengthOrder: {
    id: 'app.containers.ModelFieldMessages.singleLengthOrder',
    defaultMessage: 'Small Panel Length'
  },
  panelMaterial: {
    id: 'app.containers.ModelFieldMessages.panelMaterial',
    defaultMessage: 'Material'
  },
  nestingChildren: {
    id: 'app.containers.ModelFieldMessages.nestingChildren',
    defaultMessage: 'Nesting c.'
  },
  panelVolume: {
    id: 'app.containers.ModelFieldMessages.panelVolume',
    defaultMessage: 'Volume'
  },
  panelWeight: {
    id: 'app.containers.ModelFieldMessages.panelWeight',
    defaultMessage: 'Weight'
  },
  numberOfPanels: {
    id: 'app.containers.ModelFieldMessages.numberOfPanels',
    defaultMessage: 'Number of panels'
  },
  strengthString: {
    id: 'app.containers.ModelFieldMessages.strengthString',
    defaultMessage: 'Strength grade'
  },
  treatment: {
    id: 'app.containers.ModelFieldMessages.treatment',
    defaultMessage: 'Treatment'
  },
  hollow: {
    id: 'app.containers.ModelFieldMessages.hollow',
    defaultMessage: 'Hollow'
  },
  insulationData: {
    id: 'app.containers.ModelFieldMessages.isolationData',
    defaultMessage: 'Insulation Data'
  },
  lengthQualityData: {
    id: 'app.containers.ModelFieldMessages.lengthQualityPairs',
    defaultMessage: 'Length Quality Data'
  },
  multipieceData: {
    id: 'app.containers.ModelFieldMessages.multipieceData',
    defaultMessage: 'Multipiece Data'
  },
  notLamellaData: {
    id: 'app.containers.ModelFieldMessages.notLamellaData',
    defaultMessage: 'Not lamella Data'
  },
  material: {
    id: 'app.containers.ModelFieldMessages.material',
    defaultMessage: 'Material code'
  },
  crossMultiple: {
    id: 'app.containers.ModelFieldMessages.crossMultiple',
    defaultMessage: 'Cross multiple'
  },
  lamellasDone: {
    id: 'app.containers.ModelFieldMessages.lamellasDone',
    defaultMessage: 'Lamellas done'
  },
  lamellasReserved: {
    id: 'app.containers.ModelFieldMessages.lamellasReserved',
    defaultMessage: 'Lamellas reserved'
  },
  lamellasUsed: {
    id: 'app.containers.ModelFieldMessages.lamellasUsed',
    defaultMessage: 'Lamellas used'
  },
  materialCode: {
    id: 'app.containers.ModelFieldMessages.materialCode',
    defaultMessage: 'Material code'
  },
  pressForce: {
    id: 'app.containers.ModelFieldMessages.pressForce',
    defaultMessage: 'Press force'
  },
  jointTime: {
    id: 'app.containers.ModelFieldMessages.jointTime',
    defaultMessage: 'Joint time'
  },
  lengthLamellaCross: {
    id: 'app.containers.ModelFieldMessages.lengthLamellaCross',
    defaultMessage: 'Package length'
  },
  lamellasPackaged: {
    id: 'app.containers.ModelFieldMessages.lamellasPackaged',
    defaultMessage: 'Lamellas packaged'
  },
  sequenceNumber: {
    id: 'app.containers.ModelFieldMessages.sequenceNumber',
    defaultMessage: 'Sequence number'
  },
  lamellasLeft: {
    id: 'app.containers.ModelFieldMessages.lamellasLeft',
    defaultMessage: 'Lamellas left'
  },
  processingError: {
    id: 'app.containers.ModelFieldMessages.processingError',
    defaultMessage: 'Processing error'
  },
  brunoLohnzinken: {
    id: 'app.containers.ModelFieldMessages.brunoLohnzinken',
    defaultMessage: 'LOHNZINKEN'
  },
  brunoKVH: {
    id: 'app.containers.ModelFieldMessages.brunoKVH',
    defaultMessage: 'KVH'
  },
  brunoAusschhuss: {
    id: 'app.containers.ModelFieldMessages.brunoKVH',
    defaultMessage: 'AUSSCHUSS'
  },
  ipAddress: {
    id: 'app.containers.modelFieldMessages.ipAddress',
    defaultMessage: 'ip Address'
  },
  added_at: {
    id: 'app.containers.modelFieldMessages.added_at',
    defaultMessage: 'added at'
  },
  present: {
    id: 'app.containers.modelFieldMessages.present',
    defaultMessage: 'Present'
  },
  restart_terminal: {
    id: 'app.containers.modelFieldMessages.restart_terminal',
    defaultMessage: 'Restart terminal'
  },
  calculatedLength: {
    id: 'app.containers.modelFieldMessages.calculatedLength',
    defaultMessage: 'Cal. length'
  },
  calculatedNumLamellas: {
    id: 'app.containers.modelFieldMessages.calculatedNumLamellas',
    defaultMessage: 'Cal. num'
  },
  calculatedNumLamellasSchneider: {
    id: 'app.containers.modelFieldMessages.calculatedNumLamellasSchneider',
    defaultMessage: 'Kalk. Lamellen soll'
  },
  description: {
    id: 'app.containers.modelFieldMessages.description',
    defaultMessage: 'Description'
  },
  materialSelect: {
    id: 'app.containers.modelFieldMessages.materialSelect',
    defaultMessage: 'Material select'
  },
  isActiveInClt: {
    id: 'app.containers.modelFieldMessages.isActiveInClt',
    defaultMessage: 'Active CLT'
  },
  allowSelect: {
    id: 'app.containers.modelFieldMessages.allowSelect',
    defaultMessage: 'Select'
  },
  stored: {
    id: 'app.containers.modelFieldMessages.stored',
    defaultMessage: 'Stored'
  },
  plannedLamellas: {
    id: 'app.containers.modelFieldMessages.plannedLamellas',
    defaultMessage: 'Planned Lamellas'
  },
  cutLamellas: {
    id: 'app.containers.modelFieldMessages.cutLamellas',
    defaultMessage: 'Cut Lamellas'
  },
  identifierCode: {
    id: 'app.containers.modelFieldMessages.identifierCode',
    defaultMessage: 'Code'
  },
  sidePlanning: {
    id: 'app.containers.modelFieldMessages.sidePlanning',
    defaultMessage: 'Side Planning'
  },
  underChamfer: {
    id: 'app.containers.modelFieldMessages.underChamfer',
    defaultMessage: 'Under Chamfer'
  },
  upperChamfer: {
    id: 'app.containers.modelFieldMessages.upperChamfer',
    defaultMessage: 'Upper Chamfer'
  },
  rightMilling: {
    id: 'app.containers.modelFieldMessages.rightMilling',
    defaultMessage: 'Right Milling'
  },
  leftMilling: {
    id: 'app.containers.modelFieldMessages.leftMilling',
    defaultMessage: 'Left Milling'
  },
  sanding: {
    id: 'app.containers.modelFieldMessages.sanding',
    defaultMessage: 'Sanding'
  },
  infeedQuality: {
    id: 'app.containers.modelFieldMessages.infeedQuality',
    defaultMessage: 'Infeed Quality'
  },
  productionQuality: {
    id: 'app.containers.modelFieldMessages.productionQuality',
    defaultMessage: 'Production Quality'
  },
  actualRawWidth: {
    id: 'app.containers.modelFieldMessages.actualRawWidth',
    defaultMessage: 'Actual Raw Width'
  },
  actualRawThickness: {
    id: 'app.containers.modelFieldMessages.actualRawThickness',
    defaultMessage: 'Actual Raw Thickness'
  },
  nominalWidth: {
    id: 'app.containers.modelFieldMessages.nominalWidth',
    defaultMessage: 'Nominal Width'
  },
  nominalThickness: {
    id: 'app.containers.modelFieldMessages.nominalThickness',
    defaultMessage: 'Nominal Thickness'
  },
  minPlaningOffset: {
    id: 'app.containers.modelFieldMessages.minPlaningOffset',
    defaultMessage: 'Minimal Planing Offset'
  },
  maxPlaningOffset: {
    id: 'app.containers.modelFieldMessages.maxPlaningOffset',
    defaultMessage: 'Maximal Planing Offset'
  },
  plannedThickness: {
    id: 'app.containers.modelFieldMessages.plannedThickness',
    defaultMessage: 'Planned Thickness'
  },
  editErp: {
    id: 'app.containers.ModelFieldMessages.editErp',
    defaultMessage: 'Edit'
  },
  getThickness: {
    id: 'app.containers.ModelFieldMessages.getThickness',
    defaultMessage: 'Available Thickness'
  },
  finish: {
    id: 'app.containers.ModelFieldMessages.finish',
    defaultMessage: 'Finish'
  },
  switchFJ: {
    id: 'app.containers.ModelFieldMessages.switchFJ',
    defaultMessage: 'Switch FJ'
  },
  FJMachine: {
    id: 'app.containers.ModelFieldMessages.FJMachine',
    defaultMessage: 'Zink'
  },
  seenOnDryingStorage: {
    id: 'app.containers.ModelFieldMessages.seenOnDryingStorage',
    defaultMessage: 'In drying'
  },
  lamellasDrying: {
    id: 'app.containers.ModelFieldMessages.lamellasDrying',
    defaultMessage: 'Drying no.'
  },
  emptied: {
    id: 'app.containers.ModelFieldMessages.emptied',
    defaultMessage: 'Emptied'
  },
  noGroupMode: {
    id: 'app.containers.ModelFieldMessages.noGroupMode',
    defaultMessage: 'NGM'
  },
  fetchedFromABF: {
    id: 'app.containers.ModelFieldMessages.fetchedFromABF',
    defaultMessage: 'ABF synced'
  },
  canBeSplit: {
    id: 'app.containers.ModelFieldMessages.canBeSplit',
    defaultMessage: 'Can be split'
  },
  canBeDeleted: {
    id: 'app.containers.ModelFieldMessages.canBeDeleted',
    defaultMessage: 'canBeDeleted'
  },
  totalLamellaLength: {
    id: 'app.containers.ModelFieldMessages.totalLamellaLength',
    defaultMessage: 'Total length'
  },
  totalLamellas: {
    id: 'app.containers.ModelFieldMessages.totalLamellas',
    defaultMessage: 'Total lam'
  },
  machine: {
    id: 'app.containers.ModelFieldMessages.machine',
    defaultMessage: 'FJ'
  },
  machineSchneider: {
    id: 'app.containers.ModelFieldMessages.machineSchneider',
    defaultMessage: 'Zinke'
  },
  fragment: {
    id: 'app.containers.ModelFieldMessages.fragment',
    defaultMessage: 'Fragment'
  },
  fullyUsed: {
    id: 'app.containers.ModelFieldMessages.fullyUsed',
    defaultMessage: 'Fully used'
  },
  fullyUsedSent: {
    id: 'app.containers.ModelFieldMessages.fullyUsedSent',
    defaultMessage: 'Fully used sent'
  },
  shouldBeRemoved: {
    id: 'app.containers.ModelFieldMessages.shouldBeRemoved',
    defaultMessage: 'Should be removed'
  },
  shouldBeRemovedSent: {
    id: 'app.containers.ModelFieldMessages.shouldBeRemovedSent',
    defaultMessage: 'Should be removed sent'
  },
  splitWood: {
    id: 'app.containers.ModelFieldMessages.splitWood',
    defaultMessage: 'Split wood'
  },
  turningZinc: {
    id: 'app.containers.ModelFieldMessages.turningZinc',
    defaultMessage: 'Turning Zinc'
  },
  turningBeforePress: {
    id: 'app.containers.ModelFieldMessages.turningBeforePress',
    defaultMessage: 'Turning Before Press'
  },
  turningAfterPress: {
    id: 'app.containers.ModelFieldMessages.turningAfterPress',
    defaultMessage: 'Turning After Press'
  },
  turningEverySecond: {
    id: 'app.containers.ModelFieldMessages.turningEverySecond',
    defaultMessage: 'Turning Every Second'
  },
  addedWidthFromLongToShort: {
    id: 'app.containers.ModelFieldMessages.addedWidthFromLongToShort',
    defaultMessage: 'Added Width Long -> Short'
  },
  addedWidthFromShortToLong: {
    id: 'app.containers.ModelFieldMessages.addedWidthFromShortToLong',
    defaultMessage: 'Added Width Short -> Long'
  },
  addedWidthEndOfGroup: {
    id: 'app.containers.ModelFieldMessages.addedWidthEndOfGroup',
    defaultMessage: 'Added Width at End'
  },
  numberOfWidths: {
    id: 'app.containers.ModelFieldMessages.numberOfWidths',
    defaultMessage: 'Number Of Widths'
  },
  disableMixer: {
    id: 'app.containers.ModelFieldMessages.disableMixer',
    defaultMessage: 'Disable Mixer'
  },
  turningEveryFirst: {
    id: 'app.containers.ModelFieldMessages.turningEveryFirst',
    defaultMessage: 'Turning Every First'
  },
  cutDateString: {
    id: 'app.containers.ModelFieldMessages.cutDateString',
    defaultMessage: 'Cut date'
  },
  startOffset: {
    id: 'app.containers.ModelFieldMessages.startOffset',
    defaultMessage: 'S. off.'
  },
  endOffset: {
    id: 'app.containers.ModelFieldMessages.endOffset',
    defaultMessage: 'E. off.'
  },
  endOffsetSchneider: {
    id: 'app.containers.ModelFieldMessages.endOffsetSchneider',
    defaultMessage: 'Versatz/Offset'
  },
  insulationOffset: {
    id: 'app.containers.ModelFieldMessages.insulationOffset',
    defaultMessage: 'Insulation Offset'
  },
  multipieceOffset: {
    id: 'app.containers.ModelFieldMessages.multipieceOffset',
    defaultMessage: 'Multipiece Offset'
  },
  lamellaOffset: {
    id: 'app.containers.ModelFieldMessages.lamellaOffset',
    defaultMessage: 'Lamella Offset'
  },
  absoluteStartOffset: {
    id: 'app.containers.ModelFieldMessages.absoluteStartOffset',
    defaultMessage: 'AS. off.'
  },
  absoluteEndOffset: {
    id: 'app.containers.ModelFieldMessages.absoluteEndOffset',
    defaultMessage: 'AE. off.'
  },
  batchCutOnLength: {
    id: 'app.containers.ModelFieldMessages.batchCutOnLength',
    defaultMessage: 'Batch cut on length'
  },
  assignedDimensions: {
    id: 'app.containers.ModelFieldMessages.assignedDimensions',
    defaultMessage: 'Assigned Dimensions'
  },
  finalLength: {
    id: 'app.containers.ModelFieldMessages.finalLength',
    defaultMessage: 'Final l.'
  },
  originalLamellaLength: {
    id: 'app.containers.ModelFieldMessages.originalLamellaLength',
    defaultMessage: 'Original Length'
  },
  inputQuality: {
    id: 'app.containers.ModelFieldMessages.inputQuality',
    defaultMessage: 'Input q.'
  },
  outputQuality: {
    id: 'app.containers.ModelFieldMessages.finaloutputQualityLength',
    defaultMessage: 'Output q.'
  },
  s2Thickness: {
    id: 'app.containers.ModelFieldMessages.s2Thickness',
    defaultMessage: 'S2 Thickness'
  },
  s4Width: {
    id: 'app.containers.ModelFieldMessages.s4Width',
    defaultMessage: 'S4 Width'
  },
  s5Fold: {
    id: 'app.containers.ModelFieldMessages.s5Fold',
    defaultMessage: 'S5 Fold (0=off, 1=nut, 2=falz)'
  },
  s6Fold: {
    id: 'app.containers.ModelFieldMessages.s6Fold',
    defaultMessage: 'S6 Fold (0=off, 1=nut, 2=falz)'
  },
  s5Depth: {
    id: 'app.containers.ModelFieldMessages.s5Depth',
    defaultMessage: 'S5 Depth'
  },
  s6Depth: {
    id: 'app.containers.ModelFieldMessages.s6Depth',
    defaultMessage: 'S6 Depth'
  },
  s7Enable: {
    id: 'app.containers.ModelFieldMessages.s7Enable',
    defaultMessage: 'S7 Enable'
  },
  s8Enable: {
    id: 'app.containers.ModelFieldMessages.s8Enable',
    defaultMessage: 'S8 Enables'
  },
  turn: {
    id: 'app.containers.ModelFieldMessages.turn',
    defaultMessage: 'Turn'
  },
  stackIndex: {
    id: 'app.containers.ModelFieldMessages.stackIndex',
    defaultMessage: 'Stack index'
  },
  bypass: {
    id: 'app.containers.ModelFieldMessages.bypass',
    defaultMessage: 'Bypass'
  },
  bypassSmarty: {
    id: 'app.containers.ModelFieldMessages.bypassSmarty',
    defaultMessage: 'Bypass/Smarty'
  },
  splitcut: {
    id: 'app.containers.ModelFieldMessages.splitcut',
    defaultMessage: 'Splitcut'
  },
  multiplanSmarty: {
    id: 'app.containers.ModelFieldMessages.multiplanSmarty',
    defaultMessage: 'Multiplan/Smarty'
  },
  reportedThickness: {
    id: 'app.containers.ModelFieldMessages.reportedThickness',
    defaultMessage: 'Reported thickness'
  },
  imperialReportedThickness: {
    id: 'app.containers.ModelFieldMessages.imperialReportedThickness',
    defaultMessage: 'Nominal thickness'
  },
  imperialReportedWidth: {
    id: 'app.containers.ModelFieldMessages.imperialReportedWidth',
    defaultMessage: 'Nominal width'
  },
  groupSelect: {
    id: 'app.containers.ModelFieldMessages.groupSelect',
    defaultMessage: 'Group Select'
  },
  newCarpet: {
    id: 'app.containers.ModelFieldMessages.newCarpet',
    defaultMessage: 'New Carpet'
  },
  producedOnInsZinc: {
    id: 'app.containers.ModelFieldMessages.producedOnInsZinc',
    defaultMessage: 'Finished on ins'
  },
  skipIsoDimension: {
    id: 'app.containers.ModelFieldMessages.skipIsoDimension',
    defaultMessage: 'Skip ISO'
  },
  qLamellaNum: {
    id: 'app.containers.ModelFieldMessages.qLamellaNum',
    defaultMessage: 'C wood lam'
  },
  qInsLamellaNum: {
    id: 'app.containers.ModelFieldMessages.qInsLamellaNum',
    defaultMessage: 'C ins lam'
  },
  subtractFromID: {
    id: 'app.containers.ModelFieldMessages.subtractFromID',
    defaultMessage: 'Minus lam'
  },
  additionalInfo: {
    id: 'app.containers.ModelFieldMessages.additionalInfo',
    defaultMessage: 'Add. info'
  },
  bottomTension: {
    id: 'app.containers.ModelFieldMessages.bottomTension',
    defaultMessage: 'Bottom Tension'
  },
  bottomN1D: {
    id: 'app.containers.ModelFieldMessages.bottomN1D',
    defaultMessage: 'Bottom N1D'
  },
  coreN2M: {
    id: 'app.containers.ModelFieldMessages.coreN2M',
    defaultMessage: 'Core N2M'
  },
  topN1D: {
    id: 'app.containers.ModelFieldMessages.topN1D',
    defaultMessage: 'Top N1D'
  },
  topTension: {
    id: 'app.containers.ModelFieldMessages.topTension',
    defaultMessage: 'Top Tension'
  },
  beamStructure: {
    id: 'app.containers.ModelFieldMessages.beamStructure',
    defaultMessage: 'Beam Structure'
  },
  fragmentOpen: {
    id: 'app.containers.ModelFieldMessages.fragmentOpen',
    defaultMessage: 'F open'
  },
  externalQuality: {
    id: 'app.containers.ModelFieldMessages.externalQuality',
    defaultMessage: 'Ext. quality'
  },
  mosserPlanerTakeaway: {
    id: 'app.containers.ModelFieldMessages.mosserPlanerTakeaway',
    defaultMessage: 'Planer Width Takeaway (mm)'
  },
  position: {
    id: 'app.containers.ModelFieldMessages.position',
    defaultMessage: 'Position'
  },
  erp_order_id: {
    id: 'app.containers.ModelFieldMessages.erp_order_id',
    defaultMessage: 'Erp Id'
  },
  stagger: {
    id: 'app.containers.ModelFieldMessages.stagger',
    defaultMessage: 'Stagger'
  },
  addThickness: {
    id: 'app.containers.ModelFieldMessages.addThickness',
    defaultMessage: 'Add 3/16'
  },
  assignedPress: {
    id: 'app.containers.ModelFieldMessages.assignedPress',
    defaultMessage: 'Assigned press'
  },
  assignedFJ: {
    id: 'app.containers.ModelFieldMessages.assignedFJ',
    defaultMessage: 'Assigned FJ'
  },
  finalBeamThickness: {
    id: 'app.containers.ModelFieldMessages.finalBeamThickness',
    defaultMessage: 'Final depth (mm)'
  },
  volume: {
    id: 'app.containers.ModelFieldMessages.volume',
    defaultMessage: 'Volume'
  },
  beams: {
    id: 'app.containers.ModelFieldMessages.beams',
    defaultMessage: 'Beams'
  },
  finishedProductId: {
    id: 'app.containers.ModelFieldMessages.finishedProductId',
    defaultMessage: 'Finished product ID'
  },
  mark: {
    id: 'app.containers.ModelFieldMessages.mark',
    defaultMessage: 'Mark'
  },
  bottomHeadTakeAway: {
    id: 'app.containers.ModelFieldMessages.bottomHeadTakeAway',
    defaultMessage: 'Bottom takeaway (mm)'
  },
  labelCode: {
    id: 'app.containers.ModelFieldMessages.labelCode',
    defaultMessage: 'Label Code'
  },
  printerIdentifier: {
    id: 'app.containers.ModelFieldMessages.printerIdentifier',
    defaultMessage: 'Printer'
  },
  labelIdentifier: {
    id: 'app.containers.ModelFieldMessages.labelIdentifier',
    defaultMessage: 'Label'
  },
  printerEvents: {
    id: 'app.containers.ModelFieldMessages.printerEvents',
    defaultMessage: 'Label Preview/Print'
  },
  totalNumOfLamellas: {
    id: 'app.containers.ModelFieldMessages.totalNumOfLamellas',
    defaultMessage: 'Tot. lam. in beam'
  },
  singleLamellaThickness: {
    id: 'app.containers.ModelFieldMessages.singleLamellaThickness',
    defaultMessage: 'Single lam.'
  },
  iQuality: {
    id: 'app.containers.ModelFieldMessages.iQuality',
    defaultMessage: 'I'
  },
  zeroQuality: {
    id: 'app.containers.ModelFieldMessages.zeroQuality',
    defaultMessage: '0'
  },
  optimalGroupSize: {
    id: 'app.containers.ModelFieldMessages.optimalGroupSize',
    defaultMessage: 'Opt. size'
  },
  zPress: {
    id: 'app.containers.ModelFieldMessages.zPress',
    defaultMessage: 'ZPress'
  },
  uniPress: {
    id: 'app.containers.ModelFieldMessages.uniPress',
    defaultMessage: 'UniPress'
  },
  FJ1: {
    id: 'app.containers.ModelFieldMessages.FJ1',
    defaultMessage: 'Zink 1'
  },
  FJ2: {
    id: 'app.containers.ModelFieldMessages.FJ2',
    defaultMessage: 'Zink 2'
  },
  totalNumberOfBeams: {
    id: 'app.containers.productionline.totalNumberOfBeams',
    defaultMessage: 'Beams Made'
  },
  numberOfBeamsNeeded: {
    id: 'app.containers.productionline.numberOfBeamsNeeded',
    defaultMessage: 'Beams Needed'
  },
  zinkId: {
    id: 'app.containers.ModelFieldMessages.zinkId',
    defaultMessage: 'Zink ID'
  },
  offset: {
    id: 'app.containers.ModelFieldMessages.offset',
    defaultMessage: 'offset (mm)'
  },
  emptiedLamellas: {
    id: 'app.containers.ModelFieldMessages.emptiedLamellas',
    defaultMessage: 'Emptied lam.'
  }
});
