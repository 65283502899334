import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, Label, ReferenceLine, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import _ from 'lodash';

function ChartView(props) {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [data, setData] = useState([{ value: 0 }]);

  useEffect(() => {
    setData(_.get(props, 'data.chartData', [{ value: 0 }]));
    setMin(_.get(props, 'data.minValue', 0));
    setMax(_.get(props, 'data.maxValue', 0));
  }, [props.data]);

  const formatYAxisTick = (tickItem) => {
    if (tickItem >= 1000000) return `${(tickItem / 1000000).toFixed(1)}M`;
    if (tickItem >= 1000) return `${(tickItem / 1000).toFixed(1)}K`;
    return tickItem;
  };

  // Custom Legend Renderer
  const renderLegend = () => {
    const currentValue = data[data.length - 1]?.value || 0;
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '3px',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'whitesmoke',
          fontSize: '0.7em',
          fontWeight: 'bold'
        }}>
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span
            style={{
              display: 'inline-block',
              width: '4px',
              height: '4px',
              backgroundColor: '#f7c315',
              borderRadius: '50%',
              marginRight: '5px'
            }}></span>
          Max: {Math.round(max * 100) / 100}
        </span>

        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span
            style={{
              display: 'inline-block',
              width: '4px',
              height: '4px',
              backgroundColor: '#ff6347',
              borderRadius: '50%',
              marginRight: '5px'
            }}></span>
          Min: {Math.round(min * 100) / 100}
        </span>

        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span
            style={{
              display: 'inline-block',
              width: '4px',
              height: '4px',
              backgroundColor: '#ffffff',
              borderRadius: '50%',
              marginRight: '5px'
            }}></span>
          Current: {Math.round(currentValue * 100) / 100}
        </span>
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(80, 80, 80)',
        height: '100%',
        width: '100%',
        borderRadius: '10px',
        padding: '5px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        zIndex: 10
      }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="" tick={{ display: 'none' }} height={5} />
          <YAxis
            width={40}
            domain={[
              (dataMin) => dataMin - Math.abs(dataMin) * 0.1, // 10% padding below
              (dataMax) => dataMax + Math.abs(dataMax) * 0.1 // 10% padding above
            ]}
            tick={{ fill: 'whitesmoke', fontSize: '0.8em' }}
            tickFormatter={formatYAxisTick}
          />
          <Tooltip />
          <Legend content={renderLegend} />
          <Line type="monotone" dataKey="value" stroke="#f7c315" strokeWidth={2} dot={false} />
          <ReferenceLine y={data[data.length - 1]?.value} strokeWidth={1} stroke="white">
            <Label
              position="top"
              value={data[data.length - 1]?.value}
              style={{
                fontWeight: 'bold',
                fill: 'whitesmoke',
                stroke: 'black',
                strokeWidth: 1.5,
                paintOrder: 'stroke'
              }}
            />
          </ReferenceLine>
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

ChartView.propTypes = {
  data: PropTypes.object.isRequired
};

export default ChartView;
