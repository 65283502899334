import { useState, useEffect, useContext } from 'react';
import { UnitSystemContext } from './unitSystemContext';

const unitConversions = {
  length: {
    base: 'meters',
    units: {
      meters: 1,
      feet: 3.28084,
      inches: 39.3701,
      miles: 0.000621371,
      centimeters: 100,
      millimeters: 1000,
      kilometers: 0.001
    }
  },
  weight: {
    base: 'kilograms',
    units: {
      kilograms: 1,
      pounds: 2.20462,
      ounces: 35.274
    }
  },
  volume: {
    base: 'cubic meters',
    units: {
      'cubic meters': 1,
      'cubic centimeters': 1000000,
      'cubic inches': 61023.7441,
      'cubic feet': 35.3147,
      'cubic miles': 2.39913e-10,
      liters: 1000,
      milliliters: 1000000,
      gallons: 264.172,
      quarts: 1056.69,
      pints: 2113.38
    }
  }
};

const useUnitConverter = () => {
  const { unitSystem, toggleUnitSystem } = useContext(UnitSystemContext);

  const convertUnit = (value, type, fromUnit, targetUnit, decimalPoints = 3, shouldRound = true) => {
    // Handle empty, standalone '.', or non-numeric input
    if (value === '' || value === '.' || isNaN(parseFloat(value))) {
      return value;
    }

    const valueString = value.toString();

    // Allow unfinished decimal input (e.g., '1.')
    if (valueString.endsWith('.')) {
      return valueString;
    }

    const hasTrailingDecimalZeros = valueString.includes('.') && valueString.match(/\.0+$/);

    const numericValue = parseFloat(value);
    const { units } = unitConversions[type];

    if (!units[fromUnit] || !units[targetUnit]) {
      throw new Error(`Invalid unit provided: ${fromUnit} or ${targetUnit}`);
    }

    const metricUnits = ['meters', 'kilograms', 'cubic meters', 'centimeters', 'millimeters', 'kilometers', 'liters', 'milliliters'];

    if (unitSystem === 'metric') {
      return value;
    }

    const factorToBase = 1 / units[fromUnit];
    const factorFromBase = units[targetUnit];
    const convertedValue = numericValue * factorToBase * factorFromBase;

    if (shouldRound) {
      let roundedValue = convertedValue.toFixed(decimalPoints);

      // If the user explicitly entered trailing decimal zeros, return the original input value
      if (hasTrailingDecimalZeros) {
        return value;
      }

      // Otherwise, remove unnecessary trailing zeros
      roundedValue = parseFloat(roundedValue).toString();

      return roundedValue;
    } else {
      return convertedValue.toString();
    }
  };

  const getUnitDisplay = (firstUnit, secondUnit) => {
    return unitSystem === 'metric' ? firstUnit : secondUnit;
  };

  return {
    unitSystem,
    toggleUnitSystem,
    convertUnit,
    getUnitDisplay
  };
};

export default useUnitConverter;
