/**
 * A link to a certain page, an anchor tag
 */

import styled from 'styled-components';

const ModalContent = styled.div`
   background-color: white;
   height: calc(100% - 140px);
   color: black;
   font-size: 1rem;
   overflow: auto;
   width: 100%;
   margin: 0 auto;
   flex-grow: 1

   &.small{
    width: 45%;
    margin: 2% auto;
   }

   &.large-padding {
     padding: 25px;
   }

   &.noheader {
     height: calc(100% - 12px);
   }
  
   flex-grow : 1;

   .validationError{
      color: red;
      border-bottom: 2px red solid !important;
      font-size: 1rem;
    }

    .validationError::after {
      content: "❌";
      font-size: 1.2rem;
      //box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      border-radius: 0.4rem;
      padding: 1px;
      text-shadow: 0px 0px 0px black;
    }


   }
   
 `;

export default ModalContent;
