import modelFieldMessages from './modelFieldMessages';

class File {
  static fields = {
    timestamp: {
      key: 'timestamp',
      title: modelFieldMessages.timestamp,
      format: {
        type: 'date'
      }
    },
    fileName: {
      key: 'remark',
      type: 'string',
      title: modelFieldMessages.fileName
    },
    comment: {
      key: 'comment',
      type: 'infoIcon',
      format: {
        titleField: 'remark',
        bodyField: 'comment'
      },
      title: modelFieldMessages.comment
    },
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    upload: {
      key: 'upload',
      type: 'uploadFile',
      title: modelFieldMessages.upload,
      function: 'uploadFile'
    },
    generateItem: {
      key: 'generate',
      type: 'generateItem',
      title: modelFieldMessages.upload,
      function: 'generateItem'
    },
    processingError: {
      key: 'processingError',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.processingError
    }
  };

  static actions = {
    downloadItem: {
      key: 'download',
      type: 'downloadItem',
      dispatch: 'downloadItem'
    },
    downloadItemAnte: {
      key: 'download',
      type: 'downloadItemAnte',
      dispatch: 'downloadItemAnte',
      modal: {
        modal: true,
        buttonText: 'downloadItemAnte',
        key: 'downloadItemAnte',
        action: 'downloadItemAnte',
        icon: 'Download',
        actionData: { target: 'backend', source: 'orders/uploaded', url: '/api/v1/orders/downloadItemAnte/', items: 'selected', callType: 'POST' }
      }
    },
    playVideo: {
      key: 'play',
      type: 'playVideo',
      dispatch: 'playVideo'
    },
    deleteVideo: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteVideo',
      title: modelFieldMessages.delete,
      modal: {
        modal: true,
        buttonText: 'deleteVideo',
        key: 'deleteVideo',
        action: 'deleteVideo',
        icon: 'deleteIcon',
        actionData: {
          target: 'backend',
          source: 'files/uploadedVideos/',
          url: '/api/v1/files/uploadedVideos/remove_videos/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    deleteFile: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteFile',
      title: modelFieldMessages.delete,
      modal: {
        modal: true,
        buttonText: 'deleteFile',
        key: 'deleteFile',
        action: 'deleteFile',
        icon: 'deleteIcon',
        actionData: {
          target: 'backend',
          source: 'files/uploaded/',
          url: '/api/v1/files/uploaded/remove_files/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    deleteCsvFile: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteFile',
      title: modelFieldMessages.delete,
      modal: {
        modal: true,
        buttonText: 'deleteFile',
        key: 'deleteFile',
        action: 'deleteFile',
        icon: 'deleteIcon',
        actionData: {
          target: 'backend',
          source: 'files/uploaded/?identifier=csv',
          url: '/api/v1/files/uploaded/remove_files/',
          items: 'selected',
          callType: 'POST'
        }
      }
    }
  };
}

export default File;
